import { CardStack, CardStackProps } from "@Components/card-stack/card-stack"
import { Container } from "@Components/container/container.component"
import { useCallback, useMemo, useState } from "react"

const cards = [
	require("@Assets/SDK-illustration-1.svg"),
	require("@Assets/SDK-illustration-2.svg"),
	require("@Assets/SDK-illustration-3.svg"),
	require("@Assets/SDK-illustration-4.svg"),
	require("@Assets/SDK-illustration-5.svg"),
]

export function TheSdk() {
	const [currentUISdkActionCard, setCurrentUISdkActionCard] = useState(0)

	const [enableAutoplay, setEnableAutoplay] = useState(true)

	const cardStackAutoplayOptions = useMemo<CardStackProps["autoPlay"]>(
		() => ({
			enable: enableAutoplay,
			frequency: 5000,
		}),
		[enableAutoplay]
	)

	const nextUIActionCardClick = useCallback(() => {
		setCurrentUISdkActionCard((prev) =>
			prev === cards.length - 1 ? 0 : prev + 1
		)
		setEnableAutoplay(false)
	}, [])

	const prevUIActionCardClick = useCallback(() => {
		setCurrentUISdkActionCard((prev) =>
			prev === 0 ? cards.length - 1 : prev - 1
		)
		setEnableAutoplay(false)
	}, [])

	const setCurrentUISdkActionCardHndl = useCallback(
		(n: number) => () => {
			setCurrentUISdkActionCard(n)
			setEnableAutoplay(false)
		},
		[]
	)

	return (
		<Container
			containerID="the-sdk"
			containerSpacing=""
			containerWidth="wide"
			containerBackground="pearl">
			<div className="wrapper">
				<div className="text-wrapper">
					<div className="headline-wrapper">
						<h1 className="headline">
							<span>¸</span>
							kycDAO Widget
						</h1>
						<h4>
							The embed kycDAO widget to onboard wallet holders as trusted
							members. <br />
						</h4>
					</div>

					<div className="product-properties-listing">
						<ul>
							<li>
								<span>¸</span>
								<h4>5 min to integrate</h4>
							</li>
							<li>
								<span>¸</span>
								<h4>website and smart contract gating</h4>
							</li>
							<li>
								<span>¸</span>
								<h4>regular updates</h4>
							</li>
						</ul>

						<div className="button-wrapper">
							{/*<a id="" className="kyc-button primary ">*/}
							{/*	<i className="material-icons nav"> navigate_next </i>*/}
							{/*	<span>Watch video</span>*/}
							{/*	<i clas sName="material-icons arrow"> arrow_forward </i>*/}
							{/*</a>*/}
						</div>
					</div>
				</div>

				<div className="swiper-part">
					<div className="card-controller">
						<div className="arrows">
							<i onClick={prevUIActionCardClick} className="material-icons">
								arrow_back
							</i>
							<i onClick={nextUIActionCardClick} className="material-icons">
								arrow_forward
							</i>
						</div>

						<ul>
							<li
								className={currentUISdkActionCard === 0 ? "selected" : ""}
								onClick={setCurrentUISdkActionCardHndl(0)}>
								<i className="material-icons">chevron_right</i>
								<span>1. Connect Wallet</span>
							</li>

							<li
								className={currentUISdkActionCard === 1 ? "selected" : ""}
								onClick={setCurrentUISdkActionCardHndl(1)}>
								<i className="material-icons">chevron_right</i>
								<span>2. Verify account</span>
							</li>

							<li
								className={currentUISdkActionCard === 2 ? "selected" : ""}
								onClick={setCurrentUISdkActionCardHndl(2)}>
								<i className="material-icons">chevron_right</i>
								<span>3. Select NFT art</span>
							</li>

							<li
								className={currentUISdkActionCard === 3 ? "selected" : ""}
								onClick={setCurrentUISdkActionCardHndl(3)}>
								<i className="material-icons">chevron_right</i>
								<span>4. Select kycNFT art</span>
							</li>

							<li
								className={currentUISdkActionCard === 4 ? "selected" : ""}
								onClick={setCurrentUISdkActionCardHndl(4)}>
								<i className="material-icons">chevron_right</i>
								<span>5. Mint kycNFT</span>
							</li>
						</ul>
					</div>
					<div className="cards-wrapper">
						<CardStack
							className="illustration"
							height={600}
							width={300}
							imageUrl={cards}
							offset={8}
							currentCard={currentUISdkActionCard}
							autoPlay={cardStackAutoplayOptions}
							onAutoplayedCardChange={setCurrentUISdkActionCard}
						/>
					</div>
				</div>
			</div>
		</Container>
	)
}
