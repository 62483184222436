import {
  FC,
  lazy,
  PropsWithChildren,
  StrictMode,
  Suspense,
  useEffect,
} from "react"
import { Hero } from "@Components/hero/hero.component"
import { CareerPage } from "@Pages/careers-page/careers-page.component"
import { CompanyPage } from "@Pages/company-page/company-page.component"
import { ContactPage } from "@Pages/contact-page/contact-page"
import { PageFaq } from "@Pages/page-faq/page-faq.component"
import { PageFrontEndDeveloper } from "@Pages/page-front-end-developer/page-front-end-developer"
import { Home } from "@Pages/page-home/home-component"
import { Grid } from "@Pages/page-grid/grid-page.component"
import { AppPage } from "@Pages/join-us/app-page"
import { PagePrivacyPolicy } from "@Pages/page-privacy-policy/page-privacy-policy.component"
import { TermsAndConditions } from "@Pages/page-terms-and-conditions/page-terms-and-conditions.component"
import { PartnersPageComponent } from "@Pages/partners-page/partners-page.component"
import {
  /* createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType, */
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"
import * as Sentry from "@sentry/react"
import { Nav } from "@Components/nav/nav.component"
import { MobileNav } from "@Components/mobile-nav/mobile-nav.component"
import { FooterComponent } from "@Components/footer/footer.component"
// import { BrowserTracing } from "@sentry/tracing"
import PageNotFound from "@Pages/page-not-found"
import { GrowthBookProvider, useGrowthBook } from "@growthbook/growthbook-react"
import { growthbook, useFeatureIsOn } from "@Services/feature-flags"
import { ComingSoonPage } from "@Components/coming-soon/coming-soon"
import { ScrollToTopWrapper } from "./scrollToTopWrapper"

const PageEmailConfirm = lazy(
  () => import("@Pages/page-email-confirm/page-email-confirm.component")
)

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const __coming_soon_page = useFeatureIsOn("coming-soon")
  const gb = useGrowthBook()

  /*console.log(
    "Coming Soon:",
    __coming_soon_page,
    "(Growthbook client key:",
    Config.GrowthbookConfig.clientKey,
    ")"
  )*/
  console.log("Widget baseUrl:", Config.WidgetCoreConfig.baseUrl)
  console.log("Widget API path:", Config.WidgetCoreConfig.publicApiPath)
  console.log("Site version:", Config.siteVersion)
  console.log(
    "Enabled networks:",
    Config.WidgetCoreConfig.enabledBlockchainNetworks
  )

  const startDateTimestamp = Date.now()
  const timeEnd = new Date(2023, 1, 24, 23, 59, 59)
  const diffDays = Math.floor(
    (timeEnd.getTime() - startDateTimestamp) / 1000 / 3600 / 24
  )
  const remainingDays = Math.max(0, Math.min(4, diffDays))

  if (gb?.ready && __coming_soon_page) {
    return <ComingSoonPage remainingDays={remainingDays} />
  } else if (gb?.ready && !__coming_soon_page) {
    return (
      <>
        <Nav />
        <MobileNav />
        {children}
        <FooterComponent />
      </>
    )
  }

  return null
}

export const App = () => {
  useEffect(() => {
    growthbook.loadFeatures()
  }, [])

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  // TODO clean up Sentry related things, just disabling the init quickly because it seems to solve a conflict with Core SDK's Sentry integration
  /* Sentry.init({
    // dsn: "https=//140ceeb7496245d5b7149674bb36aa59@o1184096.ingest.sentry.io/6301609",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["localhost", "https=//kycdao.xyz"],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  }) */

  return (
    <StrictMode>
      <BrowserRouter>
        <Sentry.ErrorBoundary showDialog>
          <ScrollToTopWrapper>
            <GrowthBookProvider growthbook={growthbook}>
              <SentryRoutes>
                <Route path="sdk" element={<></>} />
                <Route
                  path="grid"
                  element={
                    <Layout>
                      <Grid />
                    </Layout>
                  }
                />
                <Route
                  path="contact"
                  element={
                    <Layout>
                      <ContactPage />
                    </Layout>
                  }
                />
                <Route
                  path="hero"
                  element={
                    <Layout>
                      <Hero />
                    </Layout>
                  }
                />
                <Route
                  path="home"
                  element={
                    <Layout>
                      <Home />
                    </Layout>
                  }
                />
                <Route
                  path="faq"
                  element={
                    <Layout>
                      <PageFaq />
                    </Layout>
                  }
                />
                <Route
                  path="join"
                  element={
                    <Layout>
                      <AppPage />
                    </Layout>
                  }
                />
                <Route
                  path="terms-and-conditions"
                  element={
                    <Layout>
                      <TermsAndConditions />
                    </Layout>
                  }
                />
                <Route
                  path="privacy-policy"
                  element={
                    <Layout>
                      <PagePrivacyPolicy />
                    </Layout>
                  }
                />
                <Route
                  path="confirm/:type"
                  element={
                    <Layout>
                      <Suspense>
                        <PageEmailConfirm />
                      </Suspense>
                    </Layout>
                  }
                />
                <Route
                  path="use-cases"
                  element={
                    <Layout>
                      <PartnersPageComponent />
                    </Layout>
                  }
                />
                <Route
                  path="company"
                  element={
                    <Layout>
                      <CompanyPage />
                    </Layout>
                  }
                />
                <Route
                  path="careers"
                  element={
                    <Layout>
                      <CareerPage />
                    </Layout>
                  }
                />
                <Route
                  path="careers/front-end-developer"
                  element={
                    <Layout>
                      <PageFrontEndDeveloper />
                    </Layout>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Layout>
                      <Home />
                    </Layout>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Layout>
                      <PageNotFound />
                    </Layout>
                  }
                />
                ,
              </SentryRoutes>
            </GrowthBookProvider>
          </ScrollToTopWrapper>
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </StrictMode>
  )
}
