import { Container } from "@Components/container/container.component"
import { LinkButton } from "@Components/link-button/link-button"
import { docSite, docs } from "@Services/adresses"
import { CodeExample } from "./code-example"

export function Integration() {
	return (
		<Container
			containerID="integration"
			containerSpacing="pt-1 pb-1"
			containerWidth="wide"
			// containerBackground="pearl"
		>
			<div className="wrapper">
				<div className="code">
					<div>
						<h1 className="headline">
							<span>¸</span>
							Integrate
						</h1>
					</div>
					<h4>by developers for developers</h4>

					<CodeExample />

					<div className="button-wrapper">
						<LinkButton href={docSite} type={"primary"} safeNewTab>
							Jump to docs
						</LinkButton>

						<LinkButton
							href={`${docs}/fullexamples`}
							type={"secondary"}
							safeNewTab>
							See SDK examples
						</LinkButton>
					</div>
				</div>

				<div className="process">
					<ul>
						<li>
							<LinkButton
								href={docs.widget}
								type="process"
								safeNewTab
								number={1}>
								Select the right Widget setup
							</LinkButton>
						</li>

						<li>
							<LinkButton
								href={docs.widget}
								type="process"
								safeNewTab
								number={2}>
								Install Widget
							</LinkButton>
						</li>

						<li>
							<LinkButton
								href={`${docs}/configuration`}
								type="process"
								safeNewTab
								number={3}>
								Configure widget
							</LinkButton>
						</li>

						<li>
							<LinkButton
								href={`${docs}/smartcontracts`}
								type="process"
								safeNewTab
								number={4}>
								Add smart contract gating
							</LinkButton>
						</li>

						<li>
							<LinkButton type="process" number={5}>
								Register domain
							</LinkButton>
						</li>

						<li>
							<LinkButton type="process" number={6}>
								Go Live
							</LinkButton>
						</li>
					</ul>
				</div>
			</div>
		</Container>
	)
}
