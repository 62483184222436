import { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"

const tooltips = ["Copy link", "Link copied"]

const LinkContainer = styled.span`
	display: flex;
	align-items: center;

	:hover {
		::before {
			right: calc(-150%);
		}
	}
`

const CopyIcon = styled.i`
	font-size: 1.3vw;
`

export function CopyablePageLink({
	children,
	href,
	label = "",
	...props
}: JSX.IntrinsicElements["a"] & { label?: string }) {
	const [tooltip, setTooltip] = useState(tooltips[0])
	const tooltipTimer = useRef<number>()

	if (!children) {
		children = href?.split(":")[1]
	}

	useEffect(() => {
		return () => {
			if (tooltipTimer) {
				window.clearTimeout(tooltipTimer.current)
			}
		}
	}, [])

	const onClick = useCallback(() => {
		if (href) {
			navigator.clipboard.writeText(href)

			setTooltip(tooltips[1])

			tooltipTimer.current = window.setTimeout(() => {
				setTooltip(tooltips[0])
			}, 1000)
		}
	}, [href])

	// ref has to go
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { ref, ...linkContainerProps } = props

	return (
		<p className="contact-mail">
			{label.split("${0}").at(0)}{" "}
			<LinkContainer
				data-tooltip={tooltip}
				onClick={onClick}
				{...linkContainerProps}>
				{children} <CopyIcon className="material-icons">content_copy</CopyIcon>
			</LinkContainer>
			{label.split("{0}").at(1)}
		</p>
	)
}
