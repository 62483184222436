import { Accordion } from "@Components/accordion/accordion.component"
import { Container } from "@Components/container/container.component"
import { Hero } from "@Components/hero/hero.component"
import { FaqOpener } from "./faq-opener"
import { FC, PropsWithChildren } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { LinkButton } from "@Components/link-button/link-button"
import {
	blog,
	discord,
	docs,
	googleForm,
	partnerForm,
} from "@Services/adresses"

const AccordionWrapper = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	grid-column: 1 / 24;
`

function FaqItem({ children, header }: PropsWithChildren<{ header: string }>) {
	return (
		<Accordion
			opener={({ toggle, isOpen }) => FaqOpener({ header, toggle, isOpen })}>
			{children}
		</Accordion>
	)
}

export const PageFaq: FC = () => {
	return (
		<div id="faq-page">
			<Hero heroClass="small" heroH1="FAQ" heroH2="" />
			<Container
				containerID=""
				containerSpacing="pt-1 pb-1"
				containerWidth="narrow">
				<AccordionWrapper>
					<FaqItem header="Trusted Anon?">
						<p>
							kycDAO members are the <b>trusted anons</b> of web3.
						</p>
						<ul>
							<li>
								trusted because of their linked compliant account to their
								self-hosted wallets.
							</li>
							<li>
								anon, or anonymous, as projects using kycDAO will not have
								personal data but know they interact with trusted partners.
							</li>
							<li>valid as they have a live membership with kycDAO</li>
						</ul>
						<p>
							Read more on trusted anons in our{" "}
							<LinkButton type="link-button" safeNewTab href={docs.membership}>
								Docs
							</LinkButton>
							.
						</p>
						<p>
							Join kycDAO as a trusted anon: <Link to="join">Join</Link>
						</p>
					</FaqItem>

					<FaqItem header="What is kycDAO">
						<p>
							kycDAO is the first interoperable web3 due diligence and
							compliance framework for wallets. It allows the ecosystem to
							develop compliant smart contracts.
						</p>
						<p>
							Learn about kycDAO in our{" "}
							<LinkButton type="link-button" safeNewTab href={docs.kycdao}>
								Docs
							</LinkButton>
							.
						</p>
					</FaqItem>

					<FaqItem header="How does kycDAO work">
						<p>
							kycDAO links self-hosted wallets with existing compliant off-chain
							accounts using a dynamic soulbound kycNFT. <br />
							dApps, smart-contracts, and web3 services use these compliant
							proofs to create trusted ecosystems.
						</p>
					</FaqItem>

					<FaqItem header="Why issue a non-transferable or soulbound NFT?">
						<p>
							Because identity should not be transferable. <br />
							Read more on our{" "}
							<LinkButton
								safeNewTab
								type="link-button"
								href={`${blog}/nft_vs_vc/`}>
								blog
							</LinkButton>
							.
						</p>
					</FaqItem>

					<FaqItem header="What is a kycNFT?">
						<p>
							These dynamic soulbound NFTs represent a trusted membership in
							kycDAO. <br />
							These web3 primitives is used by trusted dApps and services.{" "}
							<br />
							kycNFTs are the web3 version of the Twitter tick, but they are
							unique and owned by the user.{" "}
							<LinkButton safeNewTab type="link-button" href={docs.nft}>
								Learn more
							</LinkButton>
						</p>
						<p>
							*If you are an artist who is excited about unique identity NFTs,
							please reach out to us on{" "}
							<LinkButton safeNewTab type="link-button" href={discord}>
								Discord*
							</LinkButton>
						</p>
					</FaqItem>

					<FaqItem header="Really multichain?">
						<p>
							Initially we will be live on Polygon/CELO/NEAR but as time passes
							we will keep adding chains across ecosystems.
						</p>
						<p>
							Review our list on our{" "}
							<LinkButton safeNewTab type="link-button" href={docs.multiChain}>
								Docs
							</LinkButton>
							.
						</p>
						<p>
							*If you have a preferred chain, join{" "}
							<LinkButton safeNewTab type="link-button" href={discord}>
								discord
							</LinkButton>{" "}
							and tell us by voting.*
						</p>
					</FaqItem>

					<FaqItem header="Can I mint multiple KYC NFTs to a single address on the same chain?">
						<p>Not yet. Soon ;)</p>
					</FaqItem>

					<FaqItem header="Can I have the same verification linked to different wallets?">
						<p>Soon!</p>
					</FaqItem>

					<FaqItem header="Wen accredited investor?">
						<p>
							Soon, for details get in touch using this{" "}
							<LinkButton safeNewTab type="link-button" href={googleForm}>
								form
							</LinkButton>
							!
						</p>
					</FaqItem>

					<FaqItem header="Wen Business verification?">
						<p>
							Soon, for details get in touch using this{" "}
							<LinkButton safeNewTab type="link-button" href={googleForm}>
								form
							</LinkButton>
							!
						</p>
					</FaqItem>

					<FaqItem header="Who are the kycDAO partners?">
						<p>
							<b>
								Trust should be a composable building block within the
								decentralized infrastructure stack.
							</b>
						</p>
						<p>
							We invite protocols, companies, multi-sigs, DAOs, Wallets, CeFIs,
							on-ramps and Banks committed to making web3 trusted to join the
							kycDAO and create a shared web3 native composable compliance
							infrastructure. As a community, we can enable decentralized
							services to align with regulations while avoiding industry
							fragmentation and preventing centralization.
						</p>
						<br />
						<br />
						<p>
							Lear more of the framework in our{" "}
							<LinkButton safeNewTab type="link-button" href={docs.kycdao}>
								Docs
							</LinkButton>
							.
						</p>
						<br />
						<br />
						<p>
							Join/Learn more as a partner using this{" "}
							<LinkButton safeNewTab type="link-button" href={partnerForm}>
								Form
							</LinkButton>
						</p>
						<br />
						<br />
						<p>
							Ping us on{" "}
							<LinkButton safeNewTab type="link-button" href={discord}>
								Discord
							</LinkButton>{" "}
							or get in touch{" "}
							<LinkButton href="mailto:partnerships@kycdao.xyz" />.
						</p>
					</FaqItem>

					<FaqItem header="Membership at kycDAO for trusted anons?">
						<p>
							kycDAO has an annual membership fee, similar to ENS’s rent.
							<br />
							The difference is that kycDAO’s soulbound NFT expire while ENS
							revokes its rented NFTs. kycNFTs represent a compliant account
							holder, while ENS names serve as a powerful tool to simplify web3
							interactions. They are both a type of identity, but they are very
							different.
						</p>
						<br />
						<br />
						<p>
							Join kycDAO as a trusted anon:{" "}
							<LinkButton href="/join" type="link-button">
								join
							</LinkButton>
						</p>
					</FaqItem>

					<FaqItem header="How long is your membership valid?">
						<p>It’s valid as long as you sign up for.</p>
					</FaqItem>

					<FaqItem header="What if I loose access to my wallet that has a KYC NFT?">
						<p>
							Immediately get in touch with us. <br />
							Ideally on{" "}
							<LinkButton safeNewTab type="link-button" href={discord}>
								Discord
							</LinkButton>
							.
						</p>
					</FaqItem>

					<FaqItem header="Wen DAO?">
						<p>
							Gradually. We are building composable compliance and identity and
							compliance are both veri complex problems. We are doing our best
							to make things work, so we can introduce an effective governance
							for a product and not only a concept.
						</p>
						<br />
						<br />
						<p>
							Join our community to keep up to date with the latest news{" "}
							<LinkButton safeNewTab type="link-button" href={discord}>
								Discord
							</LinkButton>
							.
						</p>
					</FaqItem>

					<FaqItem header="How do I integrate kycDAO?">
						<p>
							See our quickstart guide on{" "}
							<LinkButton safeNewTab type="link-button" href={docs.quickstart}>
								Docs
							</LinkButton>
							.
						</p>
					</FaqItem>
				</AccordionWrapper>
			</Container>
		</div>
	)
}
