import styled, { css } from "styled-components"

const FaqOpenerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2rem var(--unit);
	cursor: pointer;
	border-radius: var(--br12);
	@extend .tr2;

	&:hover {
		background: var(--border);

		i {
			background: var(--kyc-normal-blue);
			color: white;
		}
	}
`

const OpenerChevron = styled.i`
	cursor: pointer;
	height: var(--button-height);
	width: var(--button-height);
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	color: white;
	border-radius: var(--br12);
	@extend .tr2;

	&:hover {
		background: var(--kyc-normal-blue);
		color: white;
	}
`

const Rotatable = styled.div<{ rotated: boolean }>`
	${({ rotated }) =>
		!rotated
			? css`
					transform: rotate(-180deg);
			  `
			: ""}
`

export function FaqOpener({
	header,
	toggle,
	isOpen,
}: {
	header: string
	toggle: () => void
	isOpen: boolean
}) {
	return (
		<FaqOpenerContainer onClick={toggle}>
			<h4>{header}</h4>
			<Rotatable rotated={isOpen}>
				<OpenerChevron className="material-icons">expand_more</OpenerChevron>
			</Rotatable>
		</FaqOpenerContainer>
	)
}
