import { Container } from "@Components/container/container.component"

export function Logos() {
	return (
		<Container
			containerID="logos"
			containerSpacing="pt-2 pb-1"
			containerWidth="wide">
			<div className="wrapper">
				<div className="availabe">
					<span>available on</span>
					<ul className="">
						<li data-tooltip="Celo">
							<img src={require("@Assets/logos/celo-onyx.svg")} />
						</li>

						<li data-tooltip="Polygon">
							<img src={require("@Assets/logos/polygon-logo.svg")} />
						</li>

						<li data-tooltip="Near">
							<img src={require("@Assets/logos/near-logo.svg")} />
						</li>

						<li data-tooltip="Solana">
							<img src={require("@Assets/logos/solana-logo.svg")} />
						</li>

						<li data-tooltip="Aptos">
							<img src={require("@Assets/logos/aptos-logo.svg")} />
						</li>
					</ul>
				</div>

				<div className="soon">
					<span>soon</span>
					<ul className="">
						<li data-tooltip="Arbitrum">
							<img src={require("@Assets/logos/arbitrum logo.svg")} />
						</li>

						<li data-tooltip="Mina">
							<img src={require("@Assets/logos/mina-logo.svg")} />
						</li>

						<li data-tooltip="Ethereum">
							<img src={require("@Assets/logos/ethereum-logo.svg")} />
						</li>
					</ul>
				</div>
			</div>
		</Container>
	)
}
