import { Container } from "@Components/container/container.component"
import { OpenWalletSelector } from "@Services/store"
import { FC, useCallback } from "react"
import MobileChainAndNetworkSelect from "@Pages/join-us/components/mobile-chain-and-network-select"
import styled from "styled-components"
import { LinkButton } from "@Components/link-button/link-button"
import { docs } from "@Services/adresses"

export const AppPage: FC = () => {
  const onStartNow = useCallback(() => {
    OpenWalletSelector.next()
  }, [])

  return (
    <div id="app-page">
      <Container
        containerSpacing="pt-1 pb-1"
        containerWidth="wide"
        containerID="join-us-header"
        containerBackground="pearl">
        <div className="hero-content-wrapper">
          <div className="headlines">
            <div className="text-wrapper">
              <div className="headline-wrapper">
                <h1>
                  <span>¸</span>
                  The last verification you ever need
                </h1>
              </div>
              <h2>
                Seamless access to trusted web3 services across blockchains and
                ecosystem.
              </h2>
              <HideOnMobile>
                <div className="button-wrapper">
                  <LinkButton type="primary" onClick={onStartNow}>
                    Start KYC now
                  </LinkButton>
                </div>
              </HideOnMobile>
            </div>
            <div className="image-wrapper">
              <img src={require("@Assets/join-us-illustration.svg")} />
            </div>
            <h4 className="trusted">
              Join kycDAO to become a <span>trusted anon </span> of web3.
            </h4>
          </div>
        </div>
      </Container>

      <MobileChainAndNetworkSelect />

      <div className="info-line free-for-a-year">
        <i className="material-icons">money_off_csred</i>
        <h4>The 1st year of kycDAO membership is free for a limited time.</h4>
      </div>

      <Container
        containerID="benefits-grid"
        containerSpacing="pb-1 pt-1"
        containerWidth="normal"
        containerBackground="">
        <div className="title-wrapper">
          <h1 className="headline">
            <span>¸</span>
            Benefits
          </h1>
          <h4>These dynamic soulbound NFTs are your membership in kycDAO.</h4>
        </div>
        <ul>
          <li>
            <h4>Reusable</h4>
            <p>You don’t have to create a new kyc during each verification.</p>
          </li>

          <li>
            <h4>Ecosystem</h4>
            <p>
              Reuse the same kycNFT with all partners, no matter where you mint.
            </p>
          </li>

          <li>
            <h4>Multichain</h4>
            <p>
              You can mint your membership proof on a growing number of chains.
              {/*[soon you will be able to link your wallets together].*/}
            </p>
          </li>

          <li>
            <h4>Soulbound: </h4>
            <p>Non-transferable. The NFT will always stay with you.</p>
          </li>

          <li>
            <h4>Personalizable</h4>
            <p>
              You make your own NFT. A variety of new tools are coming to make
              it even more personal.
            </p>
          </li>

          <li>
            <h4>No PII</h4>
            <p>kycNFTs do not contain personal information.</p>
          </li>
        </ul>
      </Container>

      <div className="info-line">
        <i className="material-icons">info</i>
        <h4>
          We are working hard to ensure that our members do not need to do an
          other KYC!
        </h4>
      </div>

      <Container
        containerID="the-nft"
        containerSpacing=""
        containerWidth="wide">
        <div className="wrapper">
          <div className="text-wrapper">
            <div className="headline-wrapper">
              <h1 className="headline">
                <span>¸</span>
                How it works
              </h1>
            </div>

            <h4>
              kycDAO links existing CeFi accounts to self custody wallets with a
              soulbound kycNFT. <br />
              Apps, smart contracts and web3 services use these compliant proofs
              to create trusted ecosystems.
            </h4>
          </div>
          <div className="illustration">
            <img src={require("@Assets/how-does-dao-works.svg")} />
          </div>
        </div>
      </Container>

      <div className="info-line">
        <i className="material-icons">info</i>
        <div>
          <h4>
            NO PII on-chain - kycNFTs do not contain personally identifiable
            information{" "}
            <LinkButton href={docs.nftMetadata} safeNewTab type="link-button">
              Learn more
            </LinkButton>
          </h4>
        </div>
      </div>

      <Container
        containerID="the-nft"
        containerSpacing="pb-1"
        containerWidth="wide">
        <div className="wrapper">
          <div className="text-wrapper">
            <div className="headline-wrapper">
              <h1 className="headline">
                <span>¸</span>
                kycNFT
              </h1>
            </div>
            <h4>
              The web3 primitive to compose compliance.
              <br />
              kycNFTs represent a valid membership in kycDAO, reusable by dApps,
              smart contracts and web3 services.
            </h4>

            <div className="product-properties-listing">
              <h4>kycNFTs</h4>
              <ul>
                <li>
                  <span>¸</span>
                  <h4>Soulbound or non transferable</h4>
                </li>
                <li>
                  <span>¸</span>
                  <h4>Unique / Personalizable / Owned</h4>
                </li>
                <li>
                  <span>¸</span>
                  <h4>Available on multiple chains</h4>
                </li>
                <li>
                  <span>¸</span>
                  <h4>Not contain personal information</h4>
                </li>
              </ul>
            </div>
          </div>
          <div className="illustration">
            <img src={require("@Assets/soulbound-nfts.svg")} />
          </div>
        </div>
      </Container>
    </div>
  )
}

const HideOnMobile = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
