import { App } from "@App/app.component"
import { createRoot } from "react-dom/client"

import "./styles.scss"

const root = document.getElementById("root")

if (root) {
	createRoot(root).render(<App />)
} else {
	console.error("Root cannot be found!")
}
