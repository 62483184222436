export const supportMail = 'mailto:support@kycdao.xyz'

export const discord = 'https://discord.gg/YZ7n6YR5aF'

export const docSite = 'https://docs.kycdao.xyz'

export const docs = {
	widget: `${docSite}/widget/`,
	learn: `${docSite}/learn/`,
	quickstart: `${docSite}/learn/`,
	membership: `${docSite}/learn/kycdao/membership/`,
	kycdao: `${docSite}/learn/kycdao/`,
	nft: `${docSite}/learn/nft/`,
	multiChain: `${docSite}/learn/multichain/`,
	nftMetadata: `${docSite}/learn/nft/nft_metadata/`,
	branding: `${docSite}/branding-guide/`,
}

export const googleForm =
	'https://docs.google.com/forms/d/e/1FAIpQLSczDBZKPGJxc8bRFpEGlKOVqfVo_5tjWbUOXnL-F1q0HehatQ/viewform'

export const partnerForm = 'https://forms.gle/Tztq9j1S5LKsypBy9'

export const partnershipMail = 'mailto:partnerships@kycdao.xyz'

export const adminMail = 'mailto:admin@kycDAO.xyz'

export const homePage = 'https://www.kycDAO.xyz'

export const discordInvite = 'https://discord.com/invite/wWMTry2V'

export const kycDAODiscord = 'https://discord.kycdao.xyz'

export const github = 'https://github.com/kycdao'

export const twitter = 'https://twitter.com/kycdao'

export const blog = 'https://blog.kycdao.xyz'

export const contactMail = 'mailto:hello@kycdao.xyz'
