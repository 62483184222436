import { Container } from "@Components/container/container.component"
import { CopyablePageLink } from "@Components/copyable-link/copyable-link"
import { LinkButton } from "@Components/link-button/link-button"
import { partnershipMail, kycDAODiscord, contactMail } from "@Services/adresses"

export function OtherContactChannels() {
	return (
		<Container
			containerSpacing=""
			containerWidth="wide"
			containerID="other-contact-channels">
			<div className="wrapper">
				<div className="channels get-in-touch">
					<div className="header">
						<h1 className="headline">
							<span>¸</span>
							Get in touch
						</h1>
					</div>
					<div className="body">
						<CopyablePageLink label="Contact email:" href={partnershipMail} />

						<div className="button-wrapper">
							<LinkButton href={kycDAODiscord} safeNewTab type={"primary"}>
								Find us on discord
							</LinkButton>

							<LinkButton href={contactMail} safeNewTab type={"secondary"}>
								Direct mail
							</LinkButton>
						</div>
					</div>
				</div>

				<div className="channels support">
					<div className="header">
						<h1 className="headline">
							<span>¸</span>
							Join us
						</h1>
					</div>
					<div className="body">
						<p>
							We invite you to join as a trusted anon, mint your personalized
							kycNFT and connect seamlessly to trusted dApps and services.
						</p>
						<div className="button-wrapper">
							<LinkButton href="/join" type="primary">
								Join us
							</LinkButton>
						</div>
					</div>
				</div>
			</div>
		</Container>
	)
}
