import { FC, useCallback, useMemo } from "react"
import useNetworksAndChains from "../../../hooks/useNetworksAndChains"
import { DropdownMenu } from "@Components/dropdownMenu/dropdownMenu"
import styled from "styled-components"
import { KycDaoClient, KycDaoWidgetConfig } from "@kycdao/widget"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { BlockchainNetworks } from "@kycdao/kycdao-sdk"

const MobileChainAndNetworkSelect: FC = () => {
	const {
		onChainSelected,
		onWalletSelected,
		currentWallet,
		currentChain,
		walletButtonConfig,
		chainButtonConfig,
	} = useNetworksAndChains()

	const widgetConfig: KycDaoWidgetConfig = useMemo(
		() => ({
			...Config.WidgetBaseConfig,
			config: {
				...Config.WidgetCoreConfig,
				// Or clause is for typescript to be happy
				enabledBlockchainNetworks: [
					currentChain || BlockchainNetworks.PolygonMumbai,
				],
				enabledVerificationTypes: Config.enabledVerificationTypes,
				evmProvider:
					currentWallet === "WalletConnect"
						? new WalletConnectProvider(Config.walletConnectConfig)
						: window.ethereum,
			},
			onReady: (sdk) => console.log(sdk),
			onFail: (reason) => console.log(reason),
			onSuccess: (data) => console.log(data),
			parent: "#mobile-modal-root",
		}),
		[currentChain, currentWallet]
	)

	const onStartKycClick = useCallback(() => {
		if (currentWallet && currentChain) {
			const kycDaoClient = new KycDaoClient(widgetConfig)
			kycDaoClient.open()
		}
	}, [currentChain, currentWallet, widgetConfig])

	return (
		<HideOnDesktop>
			<Wrapper className="start-kyc">
				<Title>Start KYC</Title>

				<FullWidthDropdownMenu
					label="Network select"
					items={chainButtonConfig}
					onChange={onChainSelected}
					value={currentChain}
				/>
				<FullWidthDropdownMenu
					onChange={onWalletSelected}
					label="Connect Wallet"
					items={walletButtonConfig}
					value={currentWallet}
				/>

				<div className="button-wrapper">
					<FullWidthButton
						disabled={!currentWallet || !currentChain}
						onClick={onStartKycClick}
						className="kyc-button primary">
						<span>Start KYC now</span>
						<i className="material-icons arrow"> arrow_forward </i>
					</FullWidthButton>
				</div>

				<div id="mobile-modal-root" />
			</Wrapper>
		</HideOnDesktop>
	)
}

const HideOnDesktop = styled.div`
	padding: 0 16px;

	@media (min-width: 1024px) {
		display: none;
		padding: 0;
	}
`

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 24px;
	padding: 16px 16px 0 16px;
	box-sizing: border-box;
	background: var(--bg-gray);
	border-radius: var(--br12);
`

const FullWidthDropdownMenu = styled(DropdownMenu)`
	width: 100%;
`

const FullWidthButton = styled.button`
	width: 100%;

	:disabled {
		opacity: 0.5;
	}
`

const Title = styled.span`
	font-family: var(--primary-font);
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 17px;
`

export default MobileChainAndNetworkSelect
