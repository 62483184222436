import { Container } from "@Components/container/container.component"

export function Nft() {
	return (
		<Container containerID="the-nft" containerSpacing="" containerWidth="wide">
			<div className="wrapper">
				<div className="text-wrapper">
					<div className="headline-wrapper">
						<h1 className="headline">
							<span>¸</span>
							About the Framework
						</h1>
					</div>
					<h4>
						kycDAO links self hosted wallets with existing regulated off-chain
						accounts using a dynamic soulbound kycNFT. <br />
						dApps, smart-contracts, and web3 services use these compliant proofs
						to create trusted ecosystems.
					</h4>
				</div>
				<div className="illustration">
					<img src={require("@Assets/how-does-dao-works.svg")} />
				</div>
			</div>
		</Container>
	)
}

export function Nft2() {
	return (
		<Container
			containerID="the-nft"
			containerSpacing="pb-1"
			containerWidth="wide">
			<div className="wrapper">
				<div className="text-wrapper">
					<div className="headline-wrapper">
						<h1 className="headline">
							<span>¸</span>
							kycNFT
						</h1>
					</div>
					<h4>
						The web3 primitive to compose compliance.
						<br />
						kycNFTs represent a valid membership in kycDAO, reusable by dApps,
						smart contracts and web3 services.
					</h4>

					<div className="product-properties-listing">
						<h4>kycNFTs</h4>
						<ul>
							<li>
								<span>¸</span>
								<h4>Soulbound or non transferable</h4>
							</li>
							<li>
								<span>¸</span>
								<h4>Unique / Personalizable / Owned</h4>
							</li>
							<li>
								<span>¸</span>
								<h4>Available on multiple chains</h4>
							</li>
							<li>
								<span>¸</span>
								<h4>Not contain personal information</h4>
							</li>
						</ul>
					</div>
				</div>
				<div className="illustration">
					<img src={require("@Assets/soulbound-nfts.svg")} />
				</div>
			</div>
		</Container>
	)
}
