import { FC, PropsWithChildren, useState } from "react"
import styled from "styled-components"

export type OpenerProps = {
	toggle: () => void
	isOpen: boolean
}

export type AccordionComponentProps = {
	hidden?: boolean
	className?: string
	opener: FC<OpenerProps>
}

const AccordionContent = styled.div<{ open: boolean }>`
	display: flex;
	flex-flow: column;
	width: 100%;
	display: ${({ open }) => (open ? "block" : "none")};
`

export const Accordion: FC<PropsWithChildren<AccordionComponentProps>> = ({
	children,
	opener,
}) => {
	const [open, setOpen] = useState(false)

	console.log(open)

	return (
		<div className="accordion-sample">
			<div className="item">
				{opener({
					toggle: () => setOpen((oldValue) => !oldValue),
					isOpen: open,
				})}
				<div>
					<AccordionContent className="content" open={open}>
						{children}
					</AccordionContent>
				</div>
			</div>
		</div>
	)
}
