import { Container } from "@Components/container/container.component"
import { CookieConsent } from "@Components/cookie-consent/cookie-consent.component"
import { useEffect } from "react"
import {
	Compliance,
	Hello,
	Integration,
	Logos,
	Nft,
	OtherContactChannels,
	Nft2,
	TheSdk,
	UseCasePart,
} from "./parts"

export const Home = () => {
	useEffect(() => {
		const el = document.getElementById("home-page")

		setTimeout(() => {
			if (el) {
				el.classList.add("loaded")
			}
		}, 1)
	}, [])

	return (
		<div className="homePage" id="home-page">
			<CookieConsent />
			<Hello />
			<Logos />
			<Nft />
			<TheSdk />
			<Integration />
			<Container containerSpacing="pt-1 " containerWidth="narrow" />
			<Nft2 />
			<Compliance />
			{/*spaceing*/}
			<Container
				containerID=""
				containerSpacing="pt-1"
				containerWidth="wide"
				containerBackground=""
			/>
			<UseCasePart />
			<OtherContactChannels />
		</div>
	)
}
