import { Container } from "@Components/container/container.component"
import { CookieConsent } from "@Components/cookie-consent/cookie-consent.component"
import { CopyablePageLink } from "@Components/copyable-link/copyable-link"
import { LinkButton } from "@Components/link-button/link-button"
import {
  contactMail,
  discordInvite,
  googleForm,
  kycDAODiscord,
  partnershipMail,
  supportMail,
} from "@Services/adresses"
import { useEffect } from "react"

export const ContactPage = () => {
  useEffect(() => {
    const el = document.getElementById("contact")

    setTimeout(() => {
      if (el) {
        el.classList.add("loaded")
      }
    }, 1)
  }, [])

  return (
    <div className="contact-page" id="contact-page">
      <CookieConsent />

      <Container
        containerSpacing="pt-1 pb-1"
        containerWidth="wide"
        containerID="hello-hero"
        containerBackground="pearl">
        <div className="hero-content-wrapper">
          <div className="headlines">
            <div className="text-wrapper">
              <div>
                <h1>
                  <span>¸</span>
                  Join as a partner
                </h1>
              </div>
              <h2>
                To join kycDAO fill out the form below, or ask us on discord
              </h2>
              <div className="button-wrapper">
                <LinkButton type="primary" href={googleForm}>
                  Fill the form
                </LinkButton>

                <LinkButton type="secondary" href={partnershipMail}>
                  Drop us a line
                </LinkButton>
              </div>

              <CopyablePageLink
                label="partnership email:"
                href={partnershipMail}
              />
            </div>
            <div className="image-wrapper">
              <img src={require("@Assets/issuers-to-validators.svg")} />
            </div>
          </div>
          <div className="reasoning">
            <h4>
              Make trust a composable building block for the decentralized
              infrastructure stack.
            </h4>
            <p>
              We invite protocols, companies, multi-sigs, DAOs, Wallets, CeFIs,
              on-ramps and Banks committed to making web3 trusted to join the
              kycDAO and create a shared web3 native composable compliance
              infrastructure. As a community, we can enable decentralized
              services to align with regulations while avoiding industry
              fragmentation and preventing centralization.
            </p>
          </div>
        </div>
      </Container>

      <Container
        containerSpacing="pt-2 "
        containerWidth="wide"
        containerID="other-contact-channels">
        <div className="wrapper">
          <div className="channels get-in-touch">
            <div className="header">
              <h1 className="headline">
                <span>¸</span>
                Get in touch
              </h1>
            </div>
            <div className="body">
              <p>
                If you want to have any question about kycdao, and composable
                compliance, drop us a line!
              </p>

              <CopyablePageLink label="Contact email:" href={partnershipMail} />

              <div className="button-wrapper">
                <LinkButton href={kycDAODiscord} safeNewTab type="primary">
                  Find us on discord
                </LinkButton>

                <LinkButton href={contactMail} safeNewTab type="secondary">
                  Direct mail
                </LinkButton>
              </div>
            </div>
          </div>

          <div className="channels support">
            <div className="header">
              <h1 className="headline">
                <span>¸</span>
                Support
              </h1>
            </div>
            <div className="body">
              <p>
                If you have any technical issues contact us at some of the
                channels below:
              </p>

              <CopyablePageLink label="Support email:" href={supportMail} />

              <div className="button-wrapper">
                <LinkButton href={discordInvite} type="primary" safeNewTab>
                  Write us on discord
                </LinkButton>

                <LinkButton href={supportMail} type="secondary" safeNewTab>
                  Direct mail
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
