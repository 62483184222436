import { Button } from "@Components/button/button.component"
import { DropdownButton } from "./dropdownButton"
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react"

import styled from "styled-components"

const ButtonContainer = styled.div`
	position: absolute;
	width: 100%;
	background: white;
	border: 2px solid black;
	display: flex;
	flex-direction: column;
	border-bottom-left-radius: var(--border-radius-light);
	border-bottom-right-radius: var(--border-radius-light);
	gap: 1em;
	box-sizing: border-box;
	color: ${(props) => props.color};
	z-index: 13;
`

const DropdownContainer = styled.div`
	position: relative;
	color: ${(props) => props.color};
`

const DropdownMenuButton = styled(Button)`
	width: 100%;
	display: flex;
	justify-content: start;
	padding-left: 16px;
	border-top: 0;
`

const DropdownMenuIcon = styled.img`
	padding-right: 0.5em;
`

export type DropdownMenuItem = {
	icon: string
	darkIcon?: string
	label: string
	className?: string
	color?: string
	id: string
}

const DropdownMenuLabel = styled.div`
	margin: auto;
`

const DropdownMenuIconContainer = styled.div`
	display: flex;
`

const DropdownMenuDivider = styled.hr`
	border: 1px inset grey;
	width: 98%;
`

export type DropdownMenuProps = {
	items: (DropdownMenuItem | null | undefined)[]
	label: string
	current?: string
	className?: string
	color?: string
	onChange?: (id?: string) => void
	value?: string
}

export type DropdownMenuImperativeHandle = {
	open: () => void
	close: () => void
}

export const DropdownMenu = forwardRef<
	DropdownMenuImperativeHandle,
	DropdownMenuProps
>(function DropdownMenu(
	{ items, label, color = "black", className, onChange, value },
	ref
) {
	const [isOpen, setOpen] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const [currentItem, setCurrentItem] = useState<DropdownMenuItem>()
	const [showDarkIcon, setShowDarkIcon] = useState(false)

	useEffect(() => {
		setCurrentItem(
			value ? items.find((item) => item?.id === value) || undefined : undefined
		)
	}, [value, items])

	/**
	 * This creates a unique type of ref, so we can call unique functions like in a html input
	 * {@link https://beta.reactjs.org/reference/react/useImperativeHandle}
	 */
	useImperativeHandle(
		ref,
		() => ({
			open: () => {
				setOpen(true)
			},
			close: () => {
				setOpen(false)
			},
		}),
		[]
	)

	const onOpen = useCallback(() => {
		setOpen((old) => !old)
	}, [])

	useEffect(() => {
		setShowDarkIcon(!!currentItem?.darkIcon)
	}, [currentItem])

	useEffect(() => {
		function clickHandler(this: Document, event: MouseEvent) {
			setTimeout(() => {
				if (
					event?.target &&
					containerRef.current &&
					!containerRef.current.contains(event.target as Node)
				) {
					setOpen(false)
				}
			})
		}
		document.addEventListener("mousedown", clickHandler)

		return () => document.removeEventListener("mousedown", clickHandler)
	}, [])

	const onSelect = useCallback(
		(item?: DropdownMenuItem) => () => {
			setCurrentItem(item)
			// item.onClick()
			onChange && onChange(item?.id)

			setOpen(false)
			setShowDarkIcon(!!item?.darkIcon)
		},
		[onChange]
	)

	const onClear = useCallback(() => {
		setCurrentItem(undefined)
		onChange && onChange(undefined)
	}, [onChange])

	const onActivated = useCallback(() => {
		setShowDarkIcon((val) => !val)
	}, [])

	const onDeactivated = useCallback(() => {
		setShowDarkIcon((val) => !val)
	}, [])

	const buttons = useMemo(() => {
		return [
			...items.map((item, i) => {
				if (item) {
					return (
						<DropdownMenuButton
							showArrow={false}
							key={item.label}
							buttonClass={className}
							onClick={onSelect(item)}>
							<DropdownMenuIconContainer>
								<DropdownMenuIcon src={item.icon} width="32" height="32" />
							</DropdownMenuIconContainer>
							<DropdownMenuLabel>
								<span>{item.label}</span>
							</DropdownMenuLabel>
						</DropdownMenuButton>
					)
				} else {
					return <DropdownMenuDivider key={`section-divider-${i}`} />
				}
			}),
			currentItem && [
				<DropdownMenuDivider key="before-clear-divider" />,
				<DropdownMenuButton
					showArrow={false}
					key="clear"
					buttonClass={className}
					onClick={onClear}>
					<DropdownMenuLabel>
						<span>Clear</span>
					</DropdownMenuLabel>
				</DropdownMenuButton>,
			],
		]
	}, [className, onSelect, items, currentItem, onClear])

	return (
		<DropdownContainer color={color} ref={containerRef}>
			{
				<DropdownButton
					onMouseLeave={onDeactivated}
					onMouseEnter={onActivated}
					buttonColor="black"
					buttonClass={className}
					isOpen={isOpen}
					onClick={onOpen}>
					{currentItem ? (
						<>
							<DropdownMenuIcon
								src={
									showDarkIcon
										? currentItem.darkIcon || currentItem.icon
										: currentItem.icon
								}
								width="32"
								height="32"
							/>
							<DropdownMenuLabel>
								<span>{currentItem.label}</span>
							</DropdownMenuLabel>
						</>
					) : (
						label
					)}
				</DropdownButton>
			}
			{isOpen && <ButtonContainer>{buttons}</ButtonContainer>}
		</DropdownContainer>
	)
})
