import { Container } from "@Components/container/container.component"
import { CopyablePageLink } from "@Components/copyable-link/copyable-link"
import { Hero } from "@Components/hero/hero.component"
import { LinkButton } from "@Components/link-button/link-button"
import { homePage, supportMail } from "@Services/adresses"
import { FC } from "react"
import styled from "styled-components"

const LastUpdated = styled.h4`
	grid-column: ${1 / 24};
`

const lastUpdated = "March 17, 2022"

export const TermsAndConditions: FC = () => {
	return (
		<div className="" id="terms-and-conditions">
			<Hero
				heroClass="small"
				heroH1="Terms and conditions"
				heroH2=""
				containerWidth="wide">
				<LastUpdated>Last Updated: {lastUpdated} </LastUpdated>
			</Hero>
			<Container
				containerID=""
				containerSpacing="pt-1 pb-1"
				containerWidth="narrow">
				<div className="text-wrapper">
					<h4>
						Veri Labs, Inc. d/b/a kycDAO <br />
						1401 21st ST Suite 5118 <br />
						Sacramento CA 95811 <br />
						<LinkButton type="link-button" href={supportMail} />
					</h4>
					<br />
					<br />
					<h4>
						Terms of Service <br />
						Last Updated: {lastUpdated}
					</h4>
					<br />
					<br />
					<p>
						Please read these Terms of Service (the <b>“Terms”</b>) carefully
						because they govern your use of the website located at kycdao.xyz
						(the <b>“Site”</b>) and the decentralized protocol for digital
						identity verification accessible via the Site offered by Veri Labs,
						Inc. d/b/a kycDAO, a company organized under the laws of the State
						of Delaware, USA (<b>“kycDAO”</b>). Our services, products, and
						materials tendered by us on, through, or in connection with the Site
						are collectively called the <b>“Services”</b>.
					</p>
					<br />
					<br />
					<p>
						WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED
						EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND KYCDAO THROUGH
						BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW
						CAREFULLY SECTIONS 16 “DISPUTE RESOLUTION” BELOW FOR DETAILS
						REGARDING ARBITRATION. HOWEVER, IN THE EVENT APPLICABLE LAW
						PROHIBITS ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN
						SECTION 16 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 15
						(GOVERNING LAW) WILL APPLY INSTEAD.
					</p>
					<br />
					<p>
						kycDAO offers decentralized digital identity and financial
						verification services (“kycDAO Protocol”), including the ability to
						create a non-transferable non-fungible cryptographic token (“kycDAO
						NFT”) for use with various services created, promoted, and/or
						maintained by third party entities, persons, or associations (“Third
						Party Collaborators”). The Services currently or will include two
						categories of decentralized verification: (1) personal identity
						confirmation of either of two levels, either confirmation of
						government-issued ID or full personal verification; and (2)
						investment accreditation.
					</p>
					<br />
					<p>
						Depending on your level of desired identity or investor
						verification, you will interact with and provide certain personal
						information to our providers for verification services, currently
						Parallel Markets, Inc. and Persona, Inc. (“Partners”). This
						information is needed to obtain the level of KYC confirmation you
						have requested. Before interacting with or providing any information
						to any of our Partners, we recommend and encourage you first read
						and understand the terms of service, if applicable, and privacy
						policies of each Partner.
					</p>
					<br />
					<p>
						Our Partners must verify identity and investor information, which
						allows them and us to comply with legal obligations, such as
						Know-Your-Customer and Anti-Money Laundering requirements. The
						results of your chosen verification are provided in a
						Know-Your-Customer report summarized, updated, and maintained in a
						unique kycDAO NFT given to the identified address of each kycDAO
						user (each a “kycDAO User” and collectively the “kycDAO kycDAO
						Users”).
					</p>
					<br />
					<p>
						The verification process is not instantaneous; depending on the type
						of service, it may take from 1 minute up to multiple days for the
						Partner to deliver the applicable verification results.
					</p>
					<ol>
						<li>
							Agreement to Terms. Please read these terms carefully. By using
							our Services or interacting with the Site, you or the person,
							entity, or organization you represent agree to be bound by these
							Terms. If you don’t agree to be bound by these Terms, stop and do
							not use the Services or interact with the Site.
						</li>
						<li>
							Privacy Policy. Please review our Privacy Policy, which also
							governs your use of the Services, for information on how we
							collect, share, and interpret your information.
						</li>
						<li>
							Changes to these Terms or the Services. We may update the Terms
							from time to time in our sole discretion. If we do, we’ll let you
							know by posting the updated Terms on the Site. It’s important that
							you review the Terms whenever we update them or you use the
							Services. If you continue to use the Services after we have posted
							updated Terms it means that you accept and agree to the changes.
							If you don’t agree to be bound by the changes, you may no longer
							user the services. Because our Services are evolving over time we
							may change or discontinue all or any part of the Services, at any
							time and without notice, at our sole discretion.
						</li>
						<li>
							Access to the Services. The Services may change from time to time
							as kycDAO evolves, refines, or adds more features to the Services.
							The kycDAO reserves the right to modify, withdraw, or discontinue
							the Services, in whole or in part, at any time without notice to
							you. You agree that the kycDAO shall have no liability to you or
							any third party for any losses or damages caused by the Services
							not being available, in whole or in part, at any time or for any
							period.
						</li>
						<li>
							Who May Use the Services?
							<ol>
								<li>
									The Services are only available to users in certain
									jurisdictions that we service who can use the Services as
									permitted under applicable law. Without limitation, you must
									be at least 18 years of age to use the Services. The
									Children’s Online Privacy Protection Act requires that online
									service providers obtain parental consent before they
									knowingly collect personally identifiable information online
									from children who are under 13 years old. We do not knowingly
									endorse nor facilitate the collection of personally
									identifiable information from children under 13 years old. If
									you are a child under 13 years old, please do not attempt to
									register for the Services or send any personal information
									about yourself to us. If we learn we have collected personal
									information from a child under 13 years old, we will delete
									that information as quickly as possible and restrict access to
									the Services and Site. If you believe that a child under 13
									years old may have provided us personal information, please
									contact us.
									<br />
									<br />
									You may not attempt to access or use the Services if you are
									not permitted to do so. By accessing the Services, you
									represent and warrant that you meet the foregoing eligibility
									criteria.
								</li>
								<li>
									To connect your wallet, you must first successfully complete
									the connection process located at{" "}
									<LinkButton href={homePage} />
								</li>
								<li>
									In order to protect the integrity of the Services, we reserve
									the right, at any time, in our sole discretion, to block
									access to the Services from certain IP addresses and unique
									device identifiers.
								</li>
							</ol>
						</li>

						<li>
							Intellectual Property Rights
							<ol>
								<li>
									<b>Ownership of Intellectual Property.</b> You acknowledge
									that all intellectual property rights, including copyrights,
									trademarks, trade secrets, and patents, in the Services, the
									Site, and their contents, features, and functionality
									(collectively, the <b>“Content”</b> ), are owned by kycDAO,
									its licensors, Partners, or other providers of such material.
									The Content is protected by U.S. and international
									intellectual property or proprietary rights laws. Neither this
									Agreement nor your access to the Services nor to any kycDAO
									NFT transfers to you any right, title, or interest in or to
									such intellectual property rights. Any rights not expressly
									granted in this Agreement are reserved by kycDAO and its
									licensors.
								</li>
								<li>
									<b>License to Use the Services.</b> kycDAO grants you a
									limited, non-exclusive, non-transferable, non-sublicensable,
									and revocable license to use and access the Content for any
									personal, business or commercial use in accordance with the
									Terms and the Privacy Policy. The Content may not be used for
									any other purpose. This license will expire upon the cessation
									or termination of your use of the Services.
								</li>
								<li>
									<b>Certain Restrictions.</b> The rights granted to you herein
									are subject to the following restrictions:
									<ol>
										<li>
											<i>No Copying or Distribution.</i> You shall not copy,
											reproduce, publish, display, perform, post, transmit, or
											distribute any part of the Content in any form or by any
											means except as expressly permitted herein or as enabled
											by a feature, product, or the Services when provided to
											you.
										</li>
										<li>
											<i>No Modifications.</i>You shall not modify, create
											derivative works from, translate, adapt, disassemble,
											reverse compile, or reverse engineer any part of the
											Content.
										</li>
										<li>
											<i>No Exploitation.</i>You shall not sell, license,
											sublicense, transfer, assign, rent, lease, loan, host, or
											otherwise exploit the Content or the Services in any way,
											whether in whole or in part.
										</li>
										<li>
											<i>No Altering of Notices.</i>You shall not delete or
											alter any copyright, trademark, or other proprietary
											rights notices from copies of the Content.
										</li>
										<li>
											<i>No Competition.</i>You shall not access or use the
											Content in order to build a similar or competitive
											website, product, or service.
										</li>
										<li>
											<i>Systematic Retrieval.</i> You shall not use any
											information retrieval system to create, compile, directly
											or indirectly, a database, compilation, collection or
											directory of the Content or other data from the Services.
										</li>
									</ol>
								</li>
								<li>
									<b>Trademark Notice.</b> All trademarks, logos, and service
									marks displayed on the Services are either kycDAO’s property
									or the property of third parties. You may not use such
									trademarks, logos, or service marks without the prior written
									consent of their respective owners.
								</li>
							</ol>
						</li>

						<li>
							<b>Assumption of Risk.</b>The information presented on or through
							the Services is made available for general information purposes
							only. kycDAO does not warrant the accuracy, completeness,
							suitability or quality of any such information. Any reliance on
							such information is strictly at your own risk. kycDAO disclaims
							all liability and responsibility arising from any reliance placed
							on such information by you or any other user to the Services, or
							by anyone who may be informed of any of its contents.
						</li>
						<li>
							<b>About the Services.</b>
							<ol>
								<li>
									Our Services allow individuals to obtain Know-Your-Customer
									(KYC) reports from recognized providers like our Partners, the
									verification proofs are represented in the unique kycDAO NFT
									provided to and maintained for the identified blockchain
									wallet address for each kycDAO User. kycDAO does not engage
									Third-Party Collaborators to perform any services and is not a
									party to any transaction or interaction between kycDAO Users
									and any Third-Party Collaborator. kycDAO has not vetted, makes
									no representations concerning, does not control, and has no
									liability for any of its kycDAO Users interactions with Third
									Party Collaborators. kycDAO does not control the services
									provided by Third Party Collaborators, nor does kycDAO assume
									any responsibility for the accuracy or reliability of any
									information provided by Third-Party Collaborators. If you are
									a kycDAO User, any transactions that you choose to engage in
									will be conducted through the blockchain network governing the
									cryptocurrency address holding the kycDAO NFT. We are not
									responsible for your blockchain address, wallet, their
									interaction with Third-Party Collaborators and other
									blockchain protocols, or the security of the private keys for
									your blockchain address.
								</li>
								<li>
									<b>kycDAO Fee.</b>We require payment of a fee for use of
									certain features of the Services (“kycDAO Fee”). We reserve
									the right to make any changes in the kycDAO Fee at our sole
									discretion. Any increase in the kycDAO Fee will be
									communicated to you at least thirty (30) days before it comes
									into effect.
								</li>
								<li>
									<b>Third-Party Links and Ads</b> The Services may contain
									links to Third Party-Collaborators, third-party websites,
									resources, and services, as well as advertisements
									(collectively, <b>“Third-Party Links”</b>). Third-Party Links
									are provided for your convenience only. kycDAO does not
									review, approve, monitor, endorse, warrant, or make any
									representations with respect to Third-Party Links. kycDAO has
									no control over the contents, products, or services of any
									Third-Party Link and accepts no responsibility for them or for
									any loss or damage that may arise from your use of them. If
									you decide to access any Third-Party Link, you do so entirely
									at your own risk and subject to the terms and conditions of
									use for such Third-Party Link. You should make whatever
									investigation you feel necessary or appropriate before
									proceeding with any transaction in connection with any
									Third-Party Link.
								</li>
								<li>
									<b>Tax Records and Reporting.</b>We reserve the right to
									report any activity occurring using the Services to relevant
									tax authorities as required under applicable law. You are
									solely responsible for maintaining all relevant tax records
									and complying with any reporting and payment requirements you
									may have as related to our Services. You are further solely
									responsible for independently maintaining the accuracy of any
									record submitted to any tax authority including any
									information derived from the Services.
								</li>
								<li>
									<b>Regulatory and Compliance Suspensions or Terminations.</b>
									We may update the validity status of your kycDAO NFT, suspend
									or terminate your access to the Services at any time in
									connection with any transaction as required by applicable law,
									any governmental authority, or if we in our sole and
									reasonable discretion determine you are violating the law,
									these Terms, the terms or policies of a Partner, or the terms
									of any Third-Party Collaborator. Such update, suspension or
									termination shall not be considered a breach of these Terms by
									kycDAO. In accordance with its anti-money laundering,
									anti-terrorism, anti-fraud, and other compliance policies and
									practices, we may reasonably update the validity status of
									your kycDAO NFT or impose reasonable limitations and controls
									on the ability of you or any Third-Party Collaborator to
									utilize the Services. Such limitations may include where good
									cause exists, updating the validity status of your kycDAO NFT,
									rejecting requests or otherwise restricting you from using the
									Services.
								</li>
							</ol>
						</li>

						<li>
							<b>Feedback.</b> We appreciate feedback, comments, ideas,
							proposals and suggestions for improvements to the Services (
							<b>“Feedback”</b>). If you choose to submit Feedback, you agree
							that we are free to use it (and permit others to use it) without
							any restriction or compensation to you.
						</li>
						<li>
							<b>General Prohibitions and kycDAO’s Enforcement Rights.</b> You
							agree not to do any of the following:
							<ol>
								<li>
									Cause or enable any illegal or fraudulent conduct associated
									with your kycDAO NFT; and/or make or cause any
									misrepresentations to be made with respect to the information
									provided or maintained to obtain and/or use your kycDAO NFT;
								</li>
								<li>
									Use, display, mirror or frame the Services or any individual
									element within the Services, kycDAO’s name, any kycDAO
									trademark, logo or other proprietary information, or the
									layout and design of any page or form contained on a page,
									without kycDAO’s express written consent;
								</li>
								<li>
									Access, tamper with, or use non-public areas of the Services,
									kycDAO’s computer systems, or the technical delivery systems
									of kycDAO’s providers;
								</li>
								<li>
									Attempt to probe, scan or test the vulnerability of any kycDAO
									system or network or breach any security or authentication
									measures;
								</li>
								<li>
									Avoid, bypass, remove, deactivate, impair, descramble or
									otherwise circumvent any technological measure implemented by
									kycDAO or any of kycDAO’s providers or any Third Party
									Collaborator (including another User) to protect the Services;
								</li>
								<li>
									Attempt to access or search the Services or download content
									from the Services using any engine, software, tool, agent,
									device or mechanism (including spiders, robots, crawlers, data
									mining tools or the like) other than the software and/or
									search agents provided by kycDAO or other generally available
									third-party web browsers;
								</li>
								<li>
									Send any unsolicited or unauthorized advertising, promotional
									materials, email, junk mail, spam, chain letters or other form
									of solicitation;
								</li>
								<li>
									Use any meta tags or other hidden text or metadata utilizing a
									kycDAO trademark, logo URL or product name without kycDAO’s
									express written consent;
								</li>
								<li>
									Use the Services, or any portion thereof, for any commercial
									purpose or for the benefit of any third party or in any manner
									not permitted by these Terms;
								</li>
								<li>
									Forge any TCP/IP packet header or any part of the header
									information in any email or newsgroup posting, or in any way
									use the Services to send altered, deceptive or false
									source-identifying information;
								</li>
								<li>
									Attempt to decipher, decompile, disassemble or reverse
									engineer any of the software used to provide the Services;
								</li>
								<li>
									Interfere with, or attempt to interfere with, the access of
									any user, host or network, including, without limitation,
									sending a virus, overloading, flooding, spamming, or
									mail-bombing the Services;
								</li>
								<li>
									Collect or store any personally identifiable information from
									the Services from other users of the Services without their
									express permission;
								</li>
								<li>
									Impersonate or misrepresent your affiliation with any
									blockchain address, blockchain wallet, person, or entity;
								</li>
								<li>
									Associate a digital asset address, wallet or account to more
									than one identity or multiple identities to more than one
									wallet or account, unless otherwise agreed to by kycDAO in
									writing;
								</li>
								<li>Violate any applicable law or regulation; or</li>
								<li>
									Encourage or enable any other individual to do any of the
									foregoing.
								</li>
								<li>Wrap or modify by any means your kycDAO NFT.</li>
							</ol>
						</li>
						<li>
							<b>Termination</b>
							<ol>
								<li>
									kycDAO may update the status of your kycDAO NFT, or suspend or
									terminate your access or rights to use the Services (including
									the kycDAO NFT) at any time for any breach of the terms of
									this Agreement. Upon termination of your access or rights to
									use the Services, your right to access and use the Services
									will immediately cease. kycDAO will not have any liability
									whatsoever to you for updating the status of your kycDAO NFT,
									any suspension or termination of your rights under these
									Terms, including for termination of your account or revocation
									of kycDAO NFT. If you have registered for an account or kycDAO
									NFT, you may seek to terminate the Services and/or invalidate
									the status of your kycDAO NFT (thereby terminating any
									verification Services) at any time by contacting kycDAO and
									requesting termination.
								</li>
								<li>
									Upon termination of the Services, any provisions of these
									Terms that by their nature should survive termination shall
									remain in full force and effect. This includes, without
									limitation, ownership or intellectual property provisions,
									warranty disclaimers, and limitations of liability.
									Termination of your access to and use of the Services shall
									not relieve you of any obligations arising or accruing prior
									to termination or limit any liability that you otherwise may
									have to kycDAO or any third party.
								</li>
							</ol>
						</li>
						<li>
							<b>Warranty Disclaimers.</b> <br />
							THE SERVICES ARE PROVIDED “AS IS” and “AS AVAILABLE” WITHOUT
							WARRANTY OF ANY KIND. USE OF THE SERVICES IS AT YOUR OWN RISK. TO
							THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WITHOUT LIMITING
							THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
							MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
							AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
							DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT THE SERVICES
							WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED,
							SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE
							QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
							RELIABILITY OF ANY INFORMATION OR CONTENT ON THE SERVICES. KYCDAO
							WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKES NO
							RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF
							THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
							CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
							INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET
							ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) CRYPTOCURRENCY
							ADDRESSES, WALLETS OR CORRUPT FILES; (IV) UNAUTHORIZED ACCESS TO
							SERVICES; OR (V) ANY THIRD PARTY ACTIVITIES, INCLUDING WITHOUT
							LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER
							MEANS OF ATTACK AGAINST ANY BLOCKCHAIN NETWORK UNDERLYING THE
							SERVICES.
							<br />
							<br />
							By accessing and using the Services, you represent that you
							understand the inherent risks associated with using cryptographic
							and blockchain-based systems, and that you have a working
							knowledge of the usage and intricacies of digital assets such as
							bitcoin (BTC), ether (ETH), and other digital tokens such as those
							following the Ethereum Token Standard (e.g., ERC-20 and ERC-721),
							IBC, or other configurations. You further understand that the
							markets for these digital assets are highly volatile due to
							factors including (but not limited to) adoption, speculation,
							technology, security, and regulation. You acknowledge that the
							cost and speed of transacting with cryptographic and
							blockchain-based systems such as Solana are variable and may
							increase or decrease (in terms of cost and/or speed of
							transaction) at any time. You understand that access to,
							functionality of, and continued operation of the cryptographic and
							blockchain-based systems are completely outside of our control.
							You further acknowledge the risk that your digital assets may lose
							some or all of their value while they are supplied to or from the
							Services and/or interact with kycDAO NFT. You further acknowledge
							that we are not responsible for any of these variables or risks
							and cannot be held liable for any resulting losses that you
							experience while accessing Services or using the kycDAO NFT.
							Accordingly, you understand and agree to assume full
							responsibility for all of the risks of accessing and using and
							interacting with the Services.
						</li>
						<li>
							<b>Indemnity.</b>
							<br />
							You will indemnify and hold kycDAO and its officers, directors,
							employees and agents, harmless from and against any claims,
							disputes, demands, liabilities, damages, losses, and costs and
							expenses, including, without limitation, reasonable legal and
							accounting fees arising out of or in any way connected with (a)
							your access to or use of the Services, the Site, or the kycDAO
							NFT, or (b) your violation of these Terms.
						</li>
						<li>
							<b>Limitation of Liability.</b>
							<ol>
								<li>
									TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER KYCDAO NOR ITS
									SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR
									DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL,
									SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR
									LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS
									OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION,
									COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE
									SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH
									THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE
									SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
									NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
									WHETHER OR NOT KYCDAO OR ITS SERVICE PROVIDERS HAS BEEN
									INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
									REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
									ESSENTIAL PURPOSE.
								</li>
								<li>
									TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE
									JURISDICTION, IN NO EVENT WILL KYCDAO’S TOTAL LIABILITY
									ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE
									USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU
									HAVE PAID OR ARE PAYABLE BY YOU TO KYCDAO FOR USE OF THE
									SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD
									ANY PAYMENT OBLIGATIONS TO KYCDAO, AS APPLICABLE.
								</li>
								<li>
									THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
									FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
									KYCDAO AND YOU.
								</li>
							</ol>
						</li>
						<li>
							<b>Governing Law and Forum Choice.</b>
							These Terms and any action related thereto will be governed by the
							Federal Arbitration Act, federal arbitration law, and the
							substantive and procedural laws of the State of Delaware, without
							regard to its conflict of laws provisions. Except as otherwise
							expressly set forth in Section 16 “Dispute Resolution,” the
							exclusive jurisdiction for all Disputes (defined below) that you
							and kycDAO are not required to arbitrate will be the state and
							federal courts located in State of Delaware, United States, and
							you and kycDAO each waive any objection to jurisdiction and venue
							in such courts.
						</li>
						<li>
							<b>Dispute Resolution.</b>
							Please read the following arbitration agreement in this Section
							carefully. It requires you to arbitrate disputes with kycDAO and
							limits the manner in which you can seek relief from us.
							<ol>
								<li>
									<b>Mandatory Arbitration of Disputes.</b>We each agree that
									any dispute, claim or controversy arising out of or relating
									to these Terms, the Site, the formation of this agreement to
									arbitrate, any kycDAO NFT or the breach, termination,
									enforcement, interpretation or validity thereof or the use of
									the Services (collectively, <b>“Disputes”</b>) will be
									resolved not in court and
									<b>
										not by a judge nor by a jury, but solely by binding,
										individual arbitration and not in a class, representative or
										consolidated action or proceeding
									</b>
									. You and kycDAO agree that the U.S. Federal Arbitration Act
									governs the interpretation and enforcement of this arbitration
									agreement, and that you and kycDAO are each waiving the right
									to a trial by jury, bench trial (trial by a judge) or to
									participate in a class action. This arbitration provision
									shall survive termination of these Terms and your use of the
									Services, including a kycDAO NFT.
								</li>
								<li>
									<b>Exceptions.</b> As limited exceptions to Section 16(a)
									above: (i) either you or kycDAO may seek to resolve a Dispute
									in small claims court if it qualifies in the jurisdiction
									wherein it was brought; (ii) we each retain the right to seek
									injunctive or other equitable relief from a court to prevent
									(or enjoin) the infringement or misappropriation of our
									intellectual property rights; and (iii) as set forth in
									Section 16(e).
								</li>
								<li>
									<b>Conducting Arbitration and Arbitration Rules.</b> The
									arbitration will be conducted by the American Arbitration
									Association (<b>“AAA”</b>) under its Consumer Arbitration
									Rules (the <b>“AAA Rules”</b>) then in effect, except as
									modified by these Terms. The AAA Rules are available at{" "}
									<LinkButton
										type="link-button"
										safeNewTab
										href="www.adr.org"
									/>{" "}
									or by calling 1-800-778-7879. A party who wishes to start
									arbitration must submit a written Demand for Arbitration to
									AAA and give notice to the other party as specified in the AAA
									Rules. The AAA provides a form Demand for Arbitration at{" "}
									<LinkButton
										type="link-button"
										safeNewTab
										href="www.adr.org"
									/>
									.
									<br />
									<br />
									Any arbitration hearings will take place virtually via remote
									audio/visual services unless you and kycDAO both agree
									otherwise. The parties agree that the arbitrator shall have
									exclusive authority to decide all issues relating to the
									formation, interpretation, applicability, enforceability and
									scope of this arbitration agreement, including but not limited
									to any claim that this arbitration agreement is void or
									voidable.
								</li>
								<li>
									<b>Arbitration Costs.</b> Payment of all filing,
									administration and arbitrator fees will be governed by the AAA
									Rules, and we won’t seek to recover the administration and
									arbitrator fees we are responsible for paying, unless the
									arbitrator finds your Dispute frivolous. If we prevail in
									arbitration we’ll pay all of our attorneys’ fees and costs and
									won’t seek to recover them from you. If you prevail in
									arbitration you will be entitled to an award of attorneys’
									fees and expenses to the extent provided under applicable law.
								</li>
								<li>
									<b> Injunctive and Declaratory Relief</b> Except as provided
									in Section 16(b) above, the arbitrator shall determine all
									issues of liability on the merits of any claim asserted by
									either party and may award declaratory or injunctive relief
									only in favor of the individual party seeking relief and only
									to the extent necessary to provide relief warranted by that
									party’s individual claim. To the extent that you or we prevail
									on a claim and seek public injunctive relief (that is,
									injunctive relief that has the primary purpose and effect of
									prohibiting unlawful acts that threaten future injury to the
									public), the entitlement to and extent of such relief must be
									litigated in a civil court of competent jurisdiction and not
									in arbitration. The parties agree that litigation of any
									issues of public injunctive relief shall be stayed pending the
									outcome of the merits of any individual claims in arbitration.
								</li>
								<li>
									<b>
										Class Action Waiver. YOU AND KYCDAO AGREE THAT EACH MAY
										BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
										INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
										IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
									</b>
									Further, if the parties’ Dispute is resolved through
									arbitration, the arbitrator may not consolidate another
									person’s claims with your claims, and may not otherwise
									preside over any form of a representative or class proceeding.
									If this specific provision is found to be unenforceable, then
									the entirety of this Dispute Resolution section shall be null
									and void.
								</li>
								<li>
									<b>Severability.</b> If an arbitrator or court of competent
									jurisdiction decides that any part of these Terms is invalid
									or unenforceable, the other parts of these Terms will still
									apply.
								</li>
							</ol>
						</li>
						<li>
							<b>General Terms.</b>
							<ol>
								<li>
									<b>Reservation of Rights.</b> kycDAO and its licensors
									exclusively own all right, title and interest in and to the
									Services, including all associated intellectual property
									rights. You acknowledge that the Services are protected by
									copyright, trademark, and other laws of the United States and
									foreign countries. You agree not to remove, alter or obscure
									any copyright, trademark, service mark or other proprietary
									rights notices incorporated in or accompanying the Services.
								</li>
								<li>
									<b>Entire Agreement.</b> These Terms and the Privacy Policy
									constitute the entire and exclusive understanding and
									agreement between kycDAO and you regarding the Services, and
									these Terms supersede and replace all prior oral or written
									understandings or agreements between kycDAO and you regarding
									the Services. If any provision of these Terms or the Privacy
									Policy is held invalid or unenforceable by an arbitrator or a
									court of competent jurisdiction, that provision will be
									enforced to the maximum extent permissible and the other
									provisions of these Terms and the Privacy Policy will remain
									in full force and effect. You may not assign or transfer these
									Terms, by operation of law or otherwise, without kycDAO’s
									prior written consent. Any attempt by you to assign or
									transfer these Terms, without such consent, will be null.
									kycDAO may freely assign or transfer these Terms without
									restriction. Subject to the foregoing, these Terms and the
									Privacy Policy will bind and inure to the benefit of the
									parties, their successors and permitted assigns. Except as
									otherwise provided herein, these Terms and the Privacy Policy
									are intended solely for the benefit of the parties and are not
									intended to confer third party beneficiary rights upon any
									other person or entity.
								</li>
								<li>
									<b>Notices.</b> Any notices or other communications provided
									by kycDAO under these Terms will be given: (i) email; and/or
									(ii)yby posting to the Services. For notices made by email,
									the date of receipt will be deemed the date on which such
									notice is transmitted.
								</li>
								<li>
									<b>Waiver of Rights.</b> kycDAO’s failure to enforce any right
									or provision of these Terms will not be considered a waiver of
									such right or provision. The waiver of any such right or
									provision will be effective only if in writing and signed by a
									duly authorized representative of kycDAO. Except as expressly
									set forth in these Terms, the exercise by either party of any
									of its remedies under these Terms will be without prejudice to
									its other remedies under these Terms or otherwise.
								</li>
							</ol>
						</li>
						<li>
							<b>Contact Information.</b>
							<br />
							If you have any questions about these Terms or the Services,
							please contact kycDAO at{" "}
							<LinkButton type="link-button" href={supportMail} />.
						</li>
					</ol>
				</div>
			</Container>
		</div>
	)
}
