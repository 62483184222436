import { Container } from "@Components/container/container.component"
import { CookieConsent } from "@Components/cookie-consent/cookie-consent.component"
import { LinkButton } from "@Components/link-button/link-button"
import { discord, supportMail } from "@Services/adresses"
import { useEffect } from "react"

export const PartnersPageComponent = () => {
	useEffect(() => {
		const el = document.getElementById("partners")

		if (el) {
			setTimeout(() => {
				el.classList.add("loaded")
			}, 1)
		}
	}, [])

	return (
		<div className="partners-page" id="partners-page">
			<CookieConsent />

			{/*<Container*/}
			{/*	containerSpacing="pt-1 pb-1"*/}
			{/*	containerWidth="wide"*/}
			{/*	containerID="hello-hero"*/}
			{/*	containerBackground="pearl">*/}
			{/*	<div className="hero-content-wrapper">*/}
			{/*		<div className="headlines">*/}
			{/*			<div className="text-wrapper">*/}
			{/*				<div>*/}
			{/*					<h1>*/}
			{/*						<span>¸</span>*/}
			{/*						Onchain KYC - Bridge IRL to web3*/}
			{/*					</h1>*/}
			{/*				</div>*/}
			{/*				<h2>*/}
			{/*					kycDAO is a multichain platform for issuing reusable, onchain*/}
			{/*					KYC verifications*/}
			{/*				</h2>*/}
			{/*				<div className="button-wrapper">*/}
			{/*					<a*/}
			{/*						target="blank"*/}
			{/*						href="https://docs.google.com/forms/d/e/1FAIpQLSczDBZKPGJxc8bRFpEGlKOVqfVo_5tjWbUOXnL-F1q0HehatQ/viewform"*/}
			{/*						id=""*/}
			{/*						className="kyc-button primary ">*/}
			{/*						<i className="material-icons nav"> navigate_next </i>*/}
			{/*						<span>Become a partner</span>*/}
			{/*						<i className="material-icons arrow"> arrow_forward </i>*/}
			{/*					</a>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="image-wrapper">*/}
			{/*				<img src={require("@Assets/hero-illustration.svg")} />*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</Container>*/}

			<Container
				containerID="testimonial-swiper"
				containerSpacing="pb-1"
				containerWidth="wide"
				containerBackground="pearl">
				<div className="wrapper">
					<div className="header">
						<h1 className="headline">
							<span>¸</span>
							Usecases
						</h1>
					</div>

					<div className="swiper">
						<ul>
							<li>
								<span>Grants & Bounties </span>
								<p>
									Grants and bounties are vast, ranging from building a
									technology stack to empowering developers and community
									members to pursue their dreams.
								</p>

								<ul>
									<li>Limit payouts to trusted members</li>
									<li>Accept applications only from trusted wallets</li>
									<li>Meet tax requirements</li>
									<li>Avoid regulatory scrutiny</li>
									<li>Build a trusted community</li>
								</ul>
							</li>

							<li>
								<span>NFTs </span>
								<p>
									As NFTs become part of pop culture, the need for trust will
									grow, and the industry will face challenges to ensure
									trustworthiness and security in the market
								</p>
								<ul>
									<li>Ensure trusted trade of NFTs (primary/secondary)</li>
									<li>
										Develop trust in founders, artists, or multisig holders
									</li>
									<li>Build a trusted NFT community</li>
									<li>Drop benefits to trusted accounts</li>
									<li>Issue bounties to trusted community members</li>
								</ul>
							</li>

							<li>
								<span>Games </span>
								<p>
									Gaming took over the top spot as the most grossing
									entertainment sector; the introduction of true ownership and
									new trading mechanisms will enable the sector to go through a
									revolution.
								</p>
								<ul>
									<li>Create a trusted player community </li>
									<li>Introduce trusted gaming NFT trade opportunities </li>
									<li>Combat bots and scammers</li>
									<li>Enable trusted and non-trusted features for users</li>
									<li>Drop benefits to trusted accounts</li>
								</ul>
							</li>

							<li>
								<span>Social</span>
								<p>
									web3 social networks give users control over their data,
									identity, and relationships, enable permissionless innovation
									and rewire the power structures of social media
								</p>
								<ul>
									<li>Use a symbol of trust</li>
									<li>Interact with only trusted anons </li>
									<li>Limit access to functions and features </li>
									<li>Combat Sybil and bot</li>
									<li>Lessen spam </li>
									<li>Gib benefits</li>
								</ul>
							</li>

							<li>
								<span>DAOs and Communities</span>
								<p>
									DAOs are an emerging organizational structure with no central
									governing body whose members share a common goal to act in the
									entity&apos;s best interest through a bottom-up management
									approach.
								</p>

								<ul>
									<li>Introduce trusted membership </li>
									<li>Limit voting to trusted anons</li>
									<li>Combat Sybil attacks</li>
									<li>Raise funds from trusted partners </li>
									<li>Trust your stewards</li>
									<li>Create a sub-DAO with only trusted wallets</li>
									<li>Issue compliant bounties</li>
									<li>Drop benefits to trusted accounts</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container
				containerID="testimonial-swiper"
				containerSpacing="pb-1"
				containerWidth="wide"
				containerBackground="pearl">
				<div className="wrapper">
					<div className="header">
						<h1 className="headline">
							<span>¸</span>
							Financial use cases
						</h1>
					</div>

					<div className="swiper">
						<ul>
							<li>
								<span>DeFi</span>
								<p>
									Decentralized finance eliminates intermediaries by allowing
									people, merchants, and businesses to conduct financial
									transactions through emerging technology <br />
									Including but not limited to trade, yield, stake, lend,
									borrow, and earn platforms.
								</p>
								<ul>
									<li>Integrate gating for trusted participants</li>
									<li>Attract liquidity that requires compliance </li>
									<li>Buidl novel compliant mechanisms</li>
								</ul>
							</li>

							<li>
								<span>RWAs</span>
								<p>
									Representing physical and traditional financial assets as
									digital tokens on a blockchain.{" "}
								</p>
								<ul>
									<li>Property</li>
									<li>Carbon credit</li>
									<li>Stocks </li>
								</ul>
							</li>

							<li>
								<span> On/Off ramps</span>
								<p>These essential payment rails allow crypto to grow.</p>
								<ul>
									<li>Interact with only pre-verified accounts</li>
									<li>Simplify compliance</li>
									<li>On-board effortlessly</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container
				containerID="contact-us"
				containerSpacing="pb-1"
				containerWidth="wide">
				<div className="wrapper">
					<div className="header">
						<h1 className="headline">
							<span>¸</span>
							Join as a partner
						</h1>
					</div>
					<div className="body">
						<p>
							If you want to have any question about kycdao, and composable
							compliance, drop us a line
						</p>
						<div className="button-wrapper">
							<LinkButton href={discord} safeNewTab type={"primary"}>
								Ping us on discord
							</LinkButton>

							<LinkButton href={supportMail} safeNewTab type={"secondary"}>
								Direct mail
							</LinkButton>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
