import { LinkButton } from "@Components/link-button/link-button"
import { blog, kycDAODiscord, twitter } from "@Services/adresses"
import { clsx } from "clsx"
import { FC, useCallback, useMemo, useState } from "react"
import { Link } from "react-router-dom"

export const MobileNav: FC = () => {
	const [active, setActive] = useState(false)

	const onSetActive = useCallback(() => {
		setActive((old) => !old)
	}, [])

	const classes = useMemo(() => {
		return clsx("mobile-menu", active && "active")
	}, [active])

	const onClick = useCallback(() => {
		console.log("called")

		setActive(false)
	}, [])

	return (
		<header id="mobile-navigation">
			<div className="load-bar" />

			<div className="nav-wrapper">
				<Link to="/get-verified" />
				<div className="menu-opener" onClick={onSetActive}>
					<span>Menu</span>
					<i className="material-icons">menu</i>
				</div>
			</div>

			<div className={classes} id="mobile-menu">
				<ul>
					<li>
						<Link onClick={onClick} to="/page-faq">
							FAQ
						</Link>
					</li>
					<li>
						<Link onClick={onClick} to="/careers-page">
							Careers
						</Link>
					</li>
					<li>
						<Link onClick={onClick} to="/partners-page">
							For Partners
						</Link>
					</li>
					<li>
						<LinkButton safeNewTab href={blog}>
							Blog
						</LinkButton>
					</li>
					<li>
						<LinkButton safeNewTab href={twitter}>
							Twitter
						</LinkButton>
					</li>
					<li>
						<LinkButton safeNewTab href={kycDAODiscord}>
							Discord
						</LinkButton>
					</li>
				</ul>
			</div>
		</header>
	)
}
