import {
	DropdownMenu,
	DropdownMenuImperativeHandle,
} from "@Components/dropdownMenu/dropdownMenu"
import { useEffect, useRef } from "react"

import { BlockchainNetworks } from "@kycdao/kycdao-sdk"
import { OpenWalletSelector as OpenChainSelector } from "@Services/store"
import type { Wallets } from "../../config/_interface"
import useNetworksAndChains from "../../hooks/useNetworksAndChains"

type AllBlockchainNetworks = keyof typeof BlockchainNetworks

export type ChainSelectorProps = {
	onChainSelectionClose: (chain: AllBlockchainNetworks, wallet: Wallets) => void
}

export function ChainSelector({ onChainSelectionClose }: ChainSelectorProps) {
	const {
		onChainSelected,
		onWalletSelected,
		currentWallet,
		currentChain,
		walletButtonConfig,
		chainButtonConfig,
	} = useNetworksAndChains()
	const chainSelectorRef = useRef<DropdownMenuImperativeHandle>(null)

	useEffect(() => {
		function openWalletSelectorHandler() {
			chainSelectorRef.current?.open()
		}

		const subscription = OpenChainSelector.subscribe(openWalletSelectorHandler)

		return () => subscription.unsubscribe()
	}, [])

	useEffect(() => {
		if (currentWallet && currentChain) {
			onChainSelectionClose(currentChain, currentWallet)
		}
	}, [currentChain, currentWallet, onChainSelectionClose])

	return (
		<div id="network-selector">
			<li>
				<DropdownMenu
					ref={chainSelectorRef}
					label="Network select"
					items={chainButtonConfig}
					onChange={onChainSelected}
					value={currentChain}
				/>
			</li>
			<li>
				<DropdownMenu
					onChange={onWalletSelected}
					label="Connect Wallet"
					items={walletButtonConfig}
					value={currentWallet}
				/>
			</li>
		</div>
	)
}
