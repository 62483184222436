import styled from "styled-components"

const LogomarkContainer = styled.div`
	display: flex;
	position: relative;
	overflow: hidden;
	border-radius: var(--border-radius-light);
	outline: 2px solid transparent;
	transition: all 0.25s cubic-bezier(0.66, 0.01, 0.17, 1);
`

const LogotypeContainer = styled.div`
	padding-left: 1rem;
`

export function Logo() {
	return (
		<>
			<LogomarkContainer className="logomark">
				<svg
					width="33"
					height="43"
					viewBox="0 0 33 43"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.5269 2.82843L29.6984 0L16.2634 13.435L12.7279 9.89949L9.89944 12.7279L16.2634 19.0919L32.5269 2.82843ZM0 2.82844L7.07107 9.89951L9.8995 7.07108L2.82843 1.10166e-05L0 2.82844Z"
						fill="#3D65F2"
					/>
					<path
						d="M32.5269 26.3934L29.6984 23.5649L16.2634 37L12.7279 33.4644L9.89944 36.2929L16.2634 42.6568L32.5269 26.3934ZM0 26.3934L7.07107 33.4644L9.8995 30.636L2.82843 23.565L0 26.3934Z"
						fill="#3D65F2"
					/>
					<path
						d="M1.19209e-07 14.3934L2.82843 11.5649L16.2635 25L19.799 21.4644L22.6274 24.2929L16.2635 30.6568L1.19209e-07 14.3934ZM32.5269 14.3934L25.4558 21.4644L22.6274 18.636L29.6984 11.565L32.5269 14.3934Z"
						fill="#3D65F2"
					/>
				</svg>
			</LogomarkContainer>
			<LogotypeContainer className="logotype">
				<svg
					viewBox="0 0 110 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0 19.6343H2.58856V12.9194L5.83108 9.93801L12.8611 19.6343H16.1308L7.60221 8.19214L15.8039 0.45661H12.4524L2.58856 10.0186V0.45661H0V19.6343Z"
						fill="black"
					/>
					<path
						d="M33.6511 0.45661H30.7083L25.0135 9.58884L19.1824 0.45661H16.1034L23.6238 11.7913V19.6343H26.2124V11.7913L33.6511 0.45661Z"
						fill="black"
					/>
					<path
						d="M52.11 6.2314C51.5378 2.14876 48.159 0 44.099 0C38.1045 0 34.8892 4.51239 34.8892 10.0992C34.8892 15.6859 37.8592 20.0909 43.8811 20.0909C48.813 20.0909 51.783 17.2169 52.3007 12.4091H49.7121C49.4942 15.5785 47.4778 17.9421 44.099 17.9421C39.4396 17.9421 37.4778 14.2892 37.4778 9.91115C37.4778 5.90909 39.4396 2.14876 44.0718 2.14876C46.7966 2.14876 48.9492 3.51859 49.5214 6.2314H52.11Z"
						fill="black"
					/>
					<path
						d="M58.0958 2.60537H62.4555C67.2239 2.60537 68.9405 5.3719 68.9405 9.93801C68.9405 17.0021 64.1721 17.4855 62.401 17.4855H58.0958V2.60537ZM55.5073 19.6343H62.183C68.6681 19.6343 71.5291 16.0083 71.5291 9.58884C71.5291 3.43801 68.1776 0.45661 62.183 0.45661H55.5073V19.6343Z"
						fill="black"
					/>
					<path
						d="M81.3412 2.76653H81.3957L84.6382 11.7107H78.0442L81.3412 2.76653ZM72.3766 19.6343H75.0469L77.2267 13.8595H85.4556L87.581 19.6343H90.442L82.8398 0.45661H79.9788L72.3766 19.6343Z"
						fill="black"
					/>
					<path
						d="M93.8599 10.0454C93.8599 6.17768 95.849 2.14876 100.59 2.14876C105.331 2.14876 107.32 6.17768 107.32 10.0454C107.32 13.9132 105.331 17.9421 100.59 17.9421C95.849 17.9421 93.8599 13.9132 93.8599 10.0454ZM91.2714 10.0454C91.2714 15.283 94.4049 20.0909 100.59 20.0909C106.776 20.0909 109.909 15.283 109.909 10.0454C109.909 4.80785 106.776 0 100.59 0C94.4049 0 91.2714 4.80785 91.2714 10.0454Z"
						fill="black"
					/>
				</svg>
			</LogotypeContainer>
		</>
	)
}
