import { Container } from "@Components/container/container.component"
import { CopyablePageLink } from "@Components/copyable-link/copyable-link"
import { LinkButton } from "@Components/link-button/link-button"
import { contactMail, discordInvite, supportMail } from "@Services/adresses"

export default function PageNotFound() {
	return (
		<div>
			<Container
				containerSpacing=""
				containerWidth="wide"
				containerID="page-404"
				containerBackground="pearl">
				<div className="wrapper">
					<div className="channels message">
						<div>
							<h1>404</h1>
						</div>
						<div>
							<h1>Error page</h1>
						</div>
						<div>
							<h3>
								Proin in tempor ligula, sed scelerisque velit. Donec pharetra
								dolor vel velit semper, et aliquam neque accumsan.
							</h3>
						</div>

						<LinkButton href="/" type="primary">
							Drop me home
						</LinkButton>
					</div>

					<div className="channels contact">
						<div className="header">
							<h1 className="">
								<span>¸</span>
								Get in touch
							</h1>
						</div>
						<div className="body">
							<p>
								If you have anything to share with us, please drop us a line!
							</p>
							<CopyablePageLink href={contactMail} />
							<div className="button-wrapper">
								<LinkButton href={discordInvite} type="primary">
									Write us on discord
								</LinkButton>

								<LinkButton href={supportMail} type="secondary">
									Direct mail
								</LinkButton>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
