import { GrowthBook } from '@growthbook/growthbook-react'
export { useFeatureIsOn, FeaturesReady } from '@growthbook/growthbook-react'

export const growthbook = new GrowthBook({
	apiHost: Config.GrowthbookConfig.apiHost,
	clientKey: Config.GrowthbookConfig.clientKey,
	enableDevMode: true,
	/*
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.variationId
    });
  }
*/
})
