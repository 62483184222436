import { clsx } from "clsx"
import { PropsWithChildren } from "react"

type LinkButtonProps = {
	type?: "primary" | "secondary" | "process" | "dark" | "blue" | "link-button"
	safeNewTab?: boolean
	number?: number
} & JSX.IntrinsicElements["a"]

export function LinkButton({
	type,
	safeNewTab,
	children,
	number,
	...props
}: PropsWithChildren<LinkButtonProps>) {
	if (safeNewTab) {
		props.rel = "noopener noreferrer"
		props.target = "_blank"
	}

	if (!children) {
		children = props.href?.split(":")[1]
	}

	switch (type) {
		case "dark":
		case "blue":
		case "primary":
		case "secondary":
			return (
				<a {...props} className={clsx("kyc-button", type, props.className)}>
					<i className="material-icons nav"> navigate_next </i>
					<span>{children}</span>
					<i className="material-icons arrow"> arrow_forward </i>
				</a>
			)
		case "process":
			return (
				<a {...props} className="step">
					{number && <span>{number}</span>}
					<p>{children}</p>
					<i className="material-icons"> arrow_forward </i>
				</a>
			)
		case "link-button":
			return (
				<a {...props} className={clsx(type, props.className)}>
					{children}
				</a>
			)
		default:
			return <a {...props}>{children}</a>
	}
}
