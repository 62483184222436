import { Container } from "@Components/container/container.component"
import { Hero } from "@Components/hero/hero.component"
import { OpenPositions } from "@Components/open-positions/open-positions.component"
import { FC, useCallback, useRef } from "react"

export const CareerPage: FC = () => {
	const careersSection = useRef<HTMLDivElement>(null)

	const scroll = useCallback(() => {
		careersSection.current?.scrollIntoView({ behavior: "smooth" })
	}, [])

	return (
		<div className="" id="career-page">
			<Hero
				heroH1="Careers"
				heroH2="TL;DR: kycDAO is committed to driving innovation to bridge DeFi primitives with TradFi requirements to create composable crypto frameworks using non-transferable NFTs."
				heroClass="large"
				containerWidth="narrow">
				<div className="sub-headline">
					<h2>
						With kycDAO anyone can solve verification and meet regulatory
						requirements within their web3 services using our verification SDKs.
					</h2>
				</div>

				<div className="button-wrapper">
					<button onClick={scroll} className="kyc-button primary">
						<i className="material-icons">navigate_next</i>
						<span>Jump to careers</span>
						<i className="material-icons arrow">arrow_forward</i>
					</button>
				</div>
			</Hero>

			<Container containerSpacing="pt-2" containerWidth="narrow">
				<div className="header">
					<i className="material-icons">language</i>
					<h4 className="bold">Location</h4>
				</div>
				<br />
				<p>
					We’re a remote first company that meets with IRL multiple times a year
					to hack/work together. We are based in Europe and the US, yet we are
					open to welcoming collaborators on a global scale.
				</p>
			</Container>

			<Container containerSpacing="pt-2" containerWidth="narrow">
				<div className="header">
					<i className="material-icons">done</i>
					<h4 className="bold">Compliance in crypto</h4>
				</div>
				<br />
				<p>
					The unregulated nature of the crypto market enabled innovation at an
					unprecedented pace since the inception of Bitcoin in 2009. Today
					crypto is part of public consciousness. Crypto tokens, non-fungible
					tokens (NFTs), decentralized autonomous organizations (DAOs), and
					decentralized finance (DeFi) have a growing presence in popular
					culture.
				</p>
				<p>
					Web3 has matured along with its recognition from sub-cultures to
					regulators. Contrary to the early days of blockchains, future-proofing
					a protocol or dapp for a new regulatory landscape can become a
					competitive advantage. Teams that understand the untapped opportunity
					of compliance in web3 will be at the forefront of an emerging
					industry: compliant crypto.
				</p>
				<p>
					This foundational variable will help accelerate the widespread
					adoption of crypto by:
				</p>

				<ul>
					<li>
						We are removing the barriers between the scale of TradFi and the
						openness and speed of DeFi.
					</li>
					<li>We are introducing shared assurance and compliance practices.</li>
					<li>We are combining the best of two worlds.</li>
				</ul>
			</Container>

			<Container containerSpacing="pt-2" containerWidth="narrow">
				<div className="header">
					<i className="material-icons">ac_unit</i>
					<h4 className="bold">Colds tarting and ecosystem</h4>
				</div>
				<br />
				<p>
					kycDAO is established to bridge two distinctive words. And while it
					might seem trivial at first, we need to start small and build towards
					the vision of compliant crypto.
				</p>
				<p>
					To have an edge, we are starting with specific target use-cases where
					we plan to be the best, learn and improve our services. As we grow
					together with our early ecosystem partners, we will have the knowledge
					and resources to start branching out toward more exclusive
					opportunities.
				</p>
				<p>
					Our success will always depend on the speed and quality of the
					execution of each step along the journey. Reusable identities are the
					only real value-added once we hit a market penetration threshold,
					hence our approach to targeted market segments.
				</p>
			</Container>

			<Container containerSpacing="pt-2 pb-1" containerWidth="narrow">
				<div className="header">
					<i className="material-icons">thumb_up</i>
					<h4 className="bold">What we appreciate in you: </h4>
				</div>
				<br />
				<p>
					While we know that every dev’s multifaceted skills and that many
					features are cross-cut across the stack, it is both unrealistic and
					inefficient to expect one person to have expertise across everything.
				</p>
				<p>
					When something is everybody’s responsibility, it is no one’s
					responsibility. That is inefficient organizationally, and when the
					metaphorical shit hits the metaphorical fan, everyone starts pointing
					fingers.
				</p>
				<p>
					Anyone at any time is welcome to contribute their time to other parts
					of the stack! But you will OWN and be responsible for the quality of
					the part you will optimize.
				</p>
			</Container>

			<Container
				containerID="application-process"
				containerSpacing="pt-2 pb-1"
				containerWidth="wide"
				containerBackground="pearl">
				<div className="wrapper">
					<h2>The application process: </h2>
					<ul className="the-process">
						<li>
							<span>1</span>
							<p>
								Apply - Introduce your self, and fill out our 5 minute form with
								your GitHub account
							</p>
						</li>
						<li>
							<span>2</span>
							<p>
								30 min long - online interview to understand your fit, and
								previous experience.
							</p>
						</li>
						<li>
							<span>3</span>
							<p>
								Complete a paid workday with Us, to have a real world experience
								about how the chemistry sparks
							</p>
						</li>
					</ul>
				</div>
			</Container>

			<Container containerSpacing="pt-1" containerWidth="wide" />

			<div ref={careersSection} />

			<OpenPositions />

			<Container containerSpacing="pt-1" containerWidth="wide" />
		</div>
	)
}
