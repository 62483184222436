import { Container } from "@Components/container/container.component"
import { LinkButton } from "@Components/link-button/link-button"
import { docSite } from "@Services/adresses"

export function Compliance() {
	return (
		<Container
			containerID="compliance"
			containerSpacing="pb-1"
			containerWidth="wide"
			containerBackground="pearl">
			<div className="wrapper">
				<div className="header">
					<h1 className="headline">
						<span>¸</span>
						Compliance
					</h1>
					<h4>
						kycDAO is the first interoperable web3 due diligence and compliance
						framework for wallets. It allows the ecosystem to develop compliant
						smart contracts.
					</h4>
				</div>
				<h4>
					The framework inherits existing web2 financial processes by relying on
					regulated compliant accounts. Each kycDAO issuer ensures compliant
					user onboarding according to their internal compliance processes,
					which must also meet the requirements set by kycDAO. These alignments
					afford a high level of trust to every issuer and the entire kycDAO
					framework. This means kycNFT holders are known by someone but operate
					as trusted anons.
				</h4>
				<LinkButton safeNewTab href={docSite} type="secondary">
					Read more about Compliance
				</LinkButton>
			</div>
		</Container>
	)
}
