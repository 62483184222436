import { TwitterIconSVG } from "@Components/svg-components/twitter-icon-svg/twitter-icon-svg.component"
import { Link } from "react-router-dom"
import { DiscordIconSvg } from "@Components/svg-components/discord-icon-svg/discord-icon-svg.component"
import { GithubIconSvg } from "@Components/svg-components/github-icon-svg/github-icon-svg.component"
import { blog, docs, github, kycDAODiscord, twitter } from "@Services/adresses"
import { LinkButton } from "@Components/link-button/link-button"

function ScrollToTop() {
	window.scrollTo(0, 0)
}

export const FooterComponent = () => {
	return (
		<footer className="">
			<div className="container wide no-grid">
				<div className="left">
					<div className="footer-logo">
						<Link onClick={ScrollToTop} to="">
							<svg
								width="33"
								height="43"
								viewBox="0 0 33 43"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M32.5269 2.82843L29.6984 0L16.2634 13.435L12.7279 9.89949L9.89944 12.7279L16.2634 19.0919L32.5269 2.82843ZM0 2.82844L7.07107 9.89951L9.8995 7.07108L2.82843 1.10166e-05L0 2.82844Z"
									fill="#3D65F2"
								/>
								<path
									d="M32.5269 26.3934L29.6984 23.5649L16.2634 37L12.7279 33.4644L9.89944 36.2929L16.2634 42.6568L32.5269 26.3934ZM0 26.3934L7.07107 33.4644L9.8995 30.636L2.82843 23.565L0 26.3934Z"
									fill="#3D65F2"
								/>
								<path
									d="M1.19209e-07 14.3934L2.82843 11.5649L16.2635 25L19.799 21.4644L22.6274 24.2929L16.2635 30.6568L1.19209e-07 14.3934ZM32.5269 14.3934L25.4558 21.4644L22.6274 18.636L29.6984 11.565L32.5269 14.3934Z"
									fill="#3D65F2"
								/>
							</svg>
						</Link>
					</div>
					<div className="left-container">
						<div className="footer-menu inner-links">
							<ul>
								<li>
									<Link to="/privacy-policy">Privacy</Link>
								</li>
								<li>
									<Link to="/terms-and-conditions">Terms & Conditions</Link>
								</li>
								<li>
									<LinkButton safeNewTab href={blog}>
										Blog
									</LinkButton>
								</li>
								<li>
									<LinkButton safeNewTab href={docs.branding}>
										Branding
									</LinkButton>
								</li>
							</ul>
						</div>
						<div className="copyright">
							<p>kycDAO © 2023. All rights reserved.</p>
							<div className="small">
								Nothing herein constitutes an offer to sell, or the solicitation
								of an offer to buy, any securities or tokens.
							</div>
						</div>
					</div>
				</div>
				<div className="right">
					<div className="footer-menu social">
						<ul>
							<li>
								<LinkButton safeNewTab href={github}>
									<GithubIconSvg className="white" />
								</LinkButton>
							</li>
							<li>
								<LinkButton href={twitter}>
									<TwitterIconSVG className="white" />
								</LinkButton>
							</li>
							<li>
								<LinkButton safeNewTab href={kycDAODiscord}>
									<DiscordIconSvg className="white" />
								</LinkButton>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div>
				<p style={{display:'none'}}>Version: {Config.siteVersion}</p>
			</div>
		</footer>
	)
}
