import { Container } from "@Components/container/container.component"
import { LinkButton } from "@Components/link-button/link-button"
import { docs, docSite } from "@Services/adresses"

export function Hello() {
	return (
		<Container
			containerSpacing="pt-1 pb-1"
			containerWidth="wide"
			containerID="hello-hero"
			containerBackground="pearl">
			<div className="hero-content-wrapper">
				<div className="headlines">
					<div className="text-wrapper">
						<div className="headline-wrapper">
							<h1>
								<span>¸</span>
								Composable Compliance
							</h1>
						</div>
						<h2>Web3 framework for compliant smart contracts </h2>
						<div className="button-wrapper">
							<LinkButton safeNewTab type="primary" href={docs.quickstart}>
								Integrate now
							</LinkButton>

							<LinkButton safeNewTab type="secondary" href={docSite}>
								Learn more
							</LinkButton>
						</div>
					</div>
					<div className="image-wrapper">
						<img src={require("@Assets/hero-illustration.svg")} />
					</div>
				</div>

				<div className="usps">
					<ul>
						<li>
							<div className="circle">
								<img src={require("@Assets/composable-compliance-blue.svg")} />
								{/*<i className="material-icons">refresh</i>*/}
							</div>
							<div className="text">
								<h4>Composable</h4>
								<p>Build compliant smart contracts using kycNFTs.</p>
							</div>
						</li>

						<li>
							<div className="circle">
								<img src={require("@Assets/privacy-preserving-blue.svg")} />
								{/*<i className="material-icons">refresh</i>*/}
							</div>
							<div className="text">
								<h4>Privacy-preserving</h4>
								<p>Protecting the privacy of kycNFT holders.</p>
							</div>
						</li>

						<li>
							<div className="circle">
								<img src={require("@Assets/trusted-blue.svg")} />
								{/*<i className="material-icons">refresh</i>*/}
							</div>
							<div className="text">
								<h4>Trusted</h4>
								<p>Use compliance via an ecosystem of regulated partners. </p>
							</div>
						</li>

						<li>
							<div className="circle">
								<img src={require("@Assets/outsourced-compliance-blue.svg")} />
							</div>
							<div className="text">
								<h4>Outsource compliance</h4>
								<p>Let the framework handle compliance.</p>
							</div>
						</li>

						<li>
							<div className="circle">
								<img src={require("@Assets/legally-binding-blue.svg")} />
							</div>
							<div className="text">
								<h4>Legally binding</h4>
								<p>Sign binding agreements using verified public-keys. </p>
							</div>
						</li>

						<li>
							<div className="circle">
								<img src={require("@Assets/frictionless-blue.svg")} />
							</div>
							<div className="text">
								<h4>Frictionless</h4>
								<p>Reuse kycNFTs to create seamless user experience.</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</Container>
	)
}
