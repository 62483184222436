import { FC, PropsWithChildren } from "react"

type HeroProps = {
	heroClass?: string
	heroH1?: string
	heroH2?: string
	containerWidth?: string
}

export const Hero: FC<PropsWithChildren<HeroProps>> = ({
	heroH2 = "home",
	heroH1 = "home",
	children,
	containerWidth = "wide",
	heroClass = "home",
}) => {
	return (
		<section id="hero" className={heroClass}>
			<div className="container-wrapper">
				<div className={`hero-content-wrapper container ${containerWidth}`}>
					<div className={"headline-wrapper"}>
						<h1>{heroH1}</h1>
						<h2>{heroH2}</h2>
					</div>
					{children}
				</div>
				<div className="illustration-wrapper">
					<img src={require("@Assets/hero-illustration.svg")} />
				</div>

				<div className="poc-content-container normal" />
			</div>
		</section>
	)
}
