import { FC, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"

export type CardStackProps = {
	imageUrl: string[]
	height: number
	width: number
	currentCard: number
	className?: string
	offset: number
	autoPlay?: { enable: boolean; frequency: number }
	onAutoplayedCardChange?: (newCard: number) => void
}

const CardContainer = styled.div<{
	width: string
	height: string
}>`
	${({ width, height }) => css`
		height: ${height};
		width: ${width}!important;
	`}
	position: relative;
`

const StyledImg = styled.img<{
	offset: string
	zIndex: number
	display: string
}>`
	${({ offset, zIndex, display }) => css`
		left: ${offset};
		top: ${offset};
		z-index: ${zIndex};
		display: ${display};
	`}
	position: absolute;
`

export const CardStack: FC<CardStackProps> = ({
	height,
	imageUrl,
	width,
	currentCard,
	offset,
	className,
	autoPlay,
	onAutoplayedCardChange,
}) => {
	const containerHeight = useMemo(
		() => height + imageUrl.length * 24 + "px",
		[imageUrl, height]
	)
	const containerWidth = useMemo(
		() => width + imageUrl.length * 24 + "px",
		[width, imageUrl]
	)

	const [autoplayCard, setAutoplayCard] = useState(currentCard)

	useEffect(() => {
		setAutoplayCard(currentCard)
	}, [currentCard])

	useEffect(() => {
		if (autoPlay?.enable) {
			const interval = setInterval(() => {
				setAutoplayCard((prev) => {
					return imageUrl.length - 1 == prev ? 0 : prev + 1
				})
			}, autoPlay.frequency)

			return () => clearInterval(interval)
		}
		return
	}, [autoplayCard, imageUrl, autoPlay])

	useEffect(() => {
		if (onAutoplayedCardChange && autoPlay?.enable) {
			onAutoplayedCardChange(autoplayCard)
		}
	}, [onAutoplayedCardChange, autoplayCard, autoPlay])

	return (
		<CardContainer
			className={className}
			height={containerHeight}
			width={containerWidth}>
			{imageUrl.map((url, i, arr) => (
				<StyledImg
					display={
						i - (autoPlay?.enable ? autoplayCard : currentCard) > -1
							? "block"
							: "none"
					}
					zIndex={arr.length - i + 1}
					offset={`${offset * i}px`}
					src={url}
					key={i}
				/>
			))}
		</CardContainer>
	)
}
