import { FC } from "react"
import { SVGProps } from "../svgProps"

export const DiscordIconSvg: FC<SVGProps> = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 171 123"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M126.098 122.881C126.098 122.881 120.832 116.591 116.443 111.032C135.607 105.619 142.921 93.6241 142.921 93.6241C136.923 97.5738 131.218 100.353 126.098 102.255C118.784 105.327 111.762 107.375 104.887 108.545C90.8434 111.178 77.9703 110.447 66.9989 108.399C58.6606 106.79 51.4926 104.449 45.4949 102.109C42.1303 100.792 38.4731 99.1829 34.816 97.1349C34.3771 96.8424 33.9383 96.6961 33.4994 96.4035C33.2069 96.2572 33.0606 96.1109 32.9143 95.9646C30.2811 94.5018 28.8183 93.4778 28.8183 93.4778C28.8183 93.4778 35.84 105.181 54.4183 110.739C50.0297 116.298 44.6171 122.881 44.6171 122.881C12.288 121.857 0 100.646 0 100.646C0 53.5418 21.0651 15.3612 21.0651 15.3612C42.1303 -0.43764 62.1714 0.00121566 62.1714 0.00121566L63.6343 1.75664C37.3029 9.3635 25.1611 20.9201 25.1611 20.9201C25.1611 20.9201 28.3794 19.1646 33.792 16.6778C49.4446 9.80235 61.8789 7.90064 66.9989 7.46179C67.8766 7.3155 68.608 7.16921 69.4857 7.16921C78.4091 5.99893 88.5029 5.70636 99.0354 6.87664C112.933 8.48579 127.854 12.5818 143.067 20.9201C143.067 20.9201 131.511 9.94864 106.642 2.34178L108.69 0.00121566C108.69 0.00121566 128.731 -0.43764 149.797 15.3612C149.797 15.3612 170.862 53.5418 170.862 100.646C170.862 100.646 158.427 121.857 126.098 122.881ZM58.0754 54.5658C49.7371 54.5658 43.1543 61.8801 43.1543 70.8035C43.1543 79.7269 49.8834 87.0412 58.0754 87.0412C66.4137 87.0412 72.9966 79.7269 72.9966 70.8035C73.1429 61.8801 66.4137 54.5658 58.0754 54.5658ZM111.47 54.5658C103.131 54.5658 96.5486 61.8801 96.5486 70.8035C96.5486 79.7269 103.278 87.0412 111.47 87.0412C119.808 87.0412 126.391 79.7269 126.391 70.8035C126.391 61.8801 119.808 54.5658 111.47 54.5658Z" />
		</svg>
	)
}
