import { Container } from "@Components/container/container.component"
import { Hero } from "@Components/hero/hero.component"
import { LinkButton } from "@Components/link-button/link-button"
import { adminMail, supportMail } from "@Services/adresses"
import styled from "styled-components"

const LastUpdated = styled.h4`
	grid-column: ${1 / 24};
`

const lastUpdated = "March 17, 2022"

export const PagePrivacyPolicy = () => {
	return (
		<div id="privacy-policy">
			<Hero
				heroClass="small"
				heroH1="Privacy Policy"
				heroH2=""
				containerWidth="wide">
				<LastUpdated>Last Updated: {lastUpdated} </LastUpdated>
			</Hero>

			<Container
				containerID=""
				containerSpacing="pt-1 pb-1"
				containerWidth="narrow">
				<div className="text-wrapper">
					<h4>
						Veri Labs, Inc. d/b/a kycDAO <br />
						1401 21st ST Suite 5118 <br />
						Sacramento CA 95811 <br />
						<LinkButton safeNewTab type="link-button" href={supportMail} />
					</h4>
					<br />
					<br />
					<h4>
						Privacy Policy
						<br />
						Effective Date: {lastUpdated}
					</h4>
					<br />
					<br />
					<p>
						Thank you for visiting kycDAO.xyz (the “Site”). We provide
						decentralized identity and financial verification services, so your
						privacy is especially important to us. This Privacy Policy (the
						“Policy”) describes the types of information Veri Labs, Inc. d/b/a
						kycDAO (“kycDAO”, “us”, “we”, or “our”) may collect, use and share
						when you visit the Site, request decentralized identity or financial
						verification services, register for, and/or use the products,
						features, others services, materials, and tokens (including
						non-transferrable and non-fungible tokens) that we offer
						(collectively with the Site, the “Services”). This Policy helps you
						understand and exercise your privacy rights. Please review it
						carefully before using the Services or sharing any personal
						information. Please also carefully review our{" "}
						<LinkButton href="/terms-and-conditions" type="link-button">
							Terms of Service
						</LinkButton>{" "}
						before using the Services. This Policy and the Terms of Service
						govern your use of the Services.
					</p>
					<br />
					<br />
					<p>
						This Policy applies to personal information collected or processed
						by us, including on the Site, our websites, mobile applications, via
						email or other electronic communication, phone / voice calls or
						chats, and other online or offline offerings. This Policy does not
						apply to information collected by third parties, including any
						websites, services, and applications that you elect to access
						through the Services. It also does not apply to any information
						collected by kycDAO offline or through any other means, including
						any other website operated by us or any third party (including our
						affiliates and subsidiaries).
					</p>
					<br />
					<p>
						kycDAO offers decentralized digital identity and financial
						verification services (“kycDAO Protocol”), including the ability to
						create a non-transferable non-fungible cryptographic token (“kycDAO
						NFT”) for use with various services created, promoted, and/or
						maintained by third party entities, persons, or associations (“Third
						Party Collaborators”). The Services currently or will include two
						categories of decentralized verification: (1) identity confirmation
						of either of two levels, either confirmation of government-issued ID
						or full personal verification; and (2) investment accreditation.
					</p>
					<br />
					<p>
						Depending on your level of desired identity or investor
						verification, you will provide certain personal information to our
						providers for verification services, currently Parallel Markets,
						Inc. and Persona, Inc. (“Partners”). This information is needed to
						obtain the level of KYC confirmation you have requested. Before
						providing any personal information to any of our Partners, we
						recommend and encourage you first read and understand the privacy
						policies of each Partner. It will apply to any personal information
						you provide that Partner.
					</p>
					<br />
					<p>
						Our Partners must verify identity and investor information, which
						allows them and us to comply with legal obligations, such as
						Know-Your-Customer and Anti-Money Laundering requirements. The
						results of your chosen verification are provided in a
						Know-Your-Customer report summarized, updated, and maintained in a
						unique kycDAO NFT given to the identified wallet of each kycDAO user
						(each a “kycDAO User” and collectively the “kycDAO Users”).
					</p>
					<br />
					<p>
						The verification process is not instantaneous; depending on the type
						of service, it may take from 1 minute up to multiple days for the
						Partner to deliver the applicable verification results.
					</p>
					<br />
					<p>
						The processes to verify identity and financial information may
						include: (i) using information provided by a kycDAO User to us or to
						a Partner to match applicable legal identification documents and
						other identity and/or investor information; (ii) screening against
						sanction, politically exposed persons, and adverse media listings;
						and (iii) confirming a user’s cryptocurrency address, wallet and/or
						kycDAO NFT ownership. The verification process is not instantaneous;
						depending on the type of service, it may take from 1 minute up to
						multiple days for the Partner to deliver the applicable verification
						results.
					</p>
					<br />
					<p>
						<b>Notice Regarding Use of Blockchain.</b> If you are a kycDAO User,
						any transactions that you choose to engage in will be conducted
						through the blockchain network governing the cryptocurrency address
						/ wallet holding the kycDAO NFT. Verification proofs are represented
						in the unique kycDAO NFT provided to and maintained for the
						identified blockchain address for each kycDAO User, will be provided
						to the applicable blockchain network and will likely be public and
						accessible to third parties due to the open nature of blockchain
						protocols. We are not responsible for your blockchain address,
						wallet, their interaction with Third-Party Collaborators and other
						blockchain protocols, or the security of the private keys for your
						blockchain address.
					</p>
					<p>
						Please review this Policy carefully. By requesting, registering for,
						accessing or using the Services (or by clicking on “accept” or
						“agree” to this Policy when prompted), you agree to the terms of
						this Policy on behalf of yourself or the entity or organization that
						you represent. If you do not agree to any term in this Policy or any
						privacy or policy of any of our Partners, you should refrain from
						further use of our Services.
					</p>

					<ol>
						<li>
							<b>Children Using or Accessing the Services.</b>
							<p>
								The Services and its content are not intended for, nor directed
								at, children under the age of eighteen (18). No one under the
								age of eighteen (18) may provide any personal information to,
								on, or in relation to the Services. Please note that we do not
								knowingly collect or any personally identifiable information
								from children under the age of eighteen (18). If you are under
								the age of eighteen (18), please do not attempt to use or
								register for the Services or send any information about yourself
								us or any Partner, including your personal identifying or
								financial information, name, address, telephone number, or email
								address. If it is determined that we have inadvertently
								collected or received personally identifiable information from a
								child under the age of eighteen (18), we shall immediately take
								the necessary steps to ensure that such information is deleted
								from our system’s database.
							</p>
						</li>

						<li>
							<b>Changes to Our Privacy Policy.</b>
							<p>
								This Policy was last revised on the date noted at the top of
								this page. We may update this Policy from time to time. If we
								make material changes, we will post the updated Policy on this
								page and notify you of such changes by means through a notice on
								the Site home page and/or email notice. Your continued use of
								the Services after we make changes is deemed to be acceptance of
								those changes, so please check the Policy periodically for
								updates.
							</p>
						</li>

						<li>
							<b>Information We Collect.</b>
							<p>
								The categories of personal information we collect depend on how
								you interact with us, our Services, and the requirements of
								applicable law. We collect information that you provide to us or
								through the Services, information we obtain automatically when
								you use our Services, and information from other sources such as
								third-party services and organizations, as described below. We
								receive or have access to several types information about you
								from various sources, including (A) information and content that
								you give us; (B) information that you provide to our Partners
								and is thereafter subject to continued maintenance and analysis
								by our Partners; and (C) automatically collected information.
								Each is described in detail below.
							</p>

							<ol>
								<li>
									<b>Information and Content That You Give Us.</b>
									<p>
										We collect personal information that you knowingly choose to
										disclose. This may include:
									</p>
								</li>
								<li>
									<b>Personal Information.</b>
									<p>
										Personal information, such as your name, address, email
										address, phone number, username for kycDAO, wallet address
										confirmation for Metamask or other blockchain wallet
										software or hardware, and any other information you directly
										provide on or through the Services. This includes
										information you provide when you register or create an
										account, or complete a questionnaire. We indirectly
										facilitate the collection and storage of the following
										personal information through our Partners. Examples of such
										personal information that we indirectly facilitate the
										collection and storage through our Partners include your
										name, email address, an online identifier (e.g., username),
										physical address, telephone number, social security number,
										driver’s license number, state or national ID card number,
										passport number, other ID card number or information, and/or
										date of birth. In some cases, you may provide our Partners
										with a visually scanned or photographed image of your face
										(e.g., a “selfie”) and/or your government issued
										identification card, driver’s license, passport, utility
										bill, bank account statement, and insurance card. This image
										may include your photograph and other information from the
										imaged document, such as your eye color, weight, height, and
										organ donor status.
									</p>
								</li>
								<li>
									<b>Transaction and Wallet Information.</b>
									<p>
										We may collect personal information and details about your
										financial transactions carried out in connection with the
										Services, including payment information. Any payments made
										to us via our Services are processed by third-party payment
										processors. We do not directly collect or store any payment
										card information entered through our Services, but we may
										receive information associated with your payment card
										information (e.g., your billing details). In addition, to
										provide the Services, we need your cryptocurrency wallet
										address (also referred to as a “public address” or “wallet
										address”) to facilitate transactions. As you use the
										Services, we might collect information about your
										cryptocurrency wallet(s) to link your kycDAO NFT to it, to
										perform proof of wallet address ownership checks, or conduct
										transactions such as fees or payments needed to facilitate
										the Services.
									</p>
								</li>
								<li>
									<b>Communications with Us.</b>
									<p>
										We may collect personal information, such as email address,
										phone number, or mailing address when you request
										information about our Services, register for our newsletter
										or loyalty program, request customer or technical support,
										apply for a job, or otherwise communicate with us.
									</p>
								</li>
								<li>
									<b>Surveys.</b>
									<p>
										We may contact you to participate in surveys. If you decide
										to participate, you may be asked to provide certain
										information which may include personal information.
									</p>
								</li>
								<li>
									<b>Interactive Features.</b>
									<p>
										e and others who use our Services may collect personal
										information that you submit or make available through any
										interactive features we may offer (e.g., messaging and chat
										features, commenting functionalities, forums, blogs, and
										social media Pages). Any information you provide through
										these means or by using the public sharing features of the
										Services will be considered “public,” unless otherwise
										required by applicable law, and is not subject to the
										privacy protections referenced herein.
									</p>
								</li>
								<li>
									<b>Sweepstakes or Contests.</b>
									<p>
										We may collect personal information you provide for any
										sweepstakes or contests that we offer. In some
										jurisdictions, we are required to publicly share information
										of sweepstakes and contest winners.
									</p>
								</li>
								<li>
									<b>Conferences, Trade Shows, and Other Events.</b>
									<p>
										We may collect personal information from individuals when we
										attend conferences, trade shows, and other events.
									</p>
								</li>
								<li>
									<b>Business Development and Strategic Partnerships. </b>
									<p>
										We may collect personal information from individuals and
										third parties to assess and pursue potential Service
										development or business opportunities.
									</p>
								</li>
								<li>
									<b>Job Applications.</b>
									<p>
										We may post job openings and opportunities on our Services.
										If you reply to one of these postings by submitting your
										application, CV and/or cover letter to us, we will collect
										and use your information to assess your qualifications.
									</p>
								</li>
								<li>
									<b>Information We Collect Automatically.</b>
									<br />
									<p>
										We may use a variety of technologies to collect certain
										information about your equipment, browsing actions, and
										patterns whenever you interact with the Services, including:
									</p>

									<ul>
										<li>
											<b>Activity Information.</b>
											<p>
												Details of your visits to our Services, including the
												types of content you view or engage with; the features
												you use; the actions you take; the people or accounts
												you interact with; the time, frequency, and duration of
												your activities; other information about your use of and
												actions on the Services; Pages that you visit before,
												during and after using our Services, information about
												the links you click, the types of content you interact
												with, the frequency and duration of your activities, and
												other information about how you use our Services. In
												addition, we may collect information that other people
												provide about you when they use our Services, including
												information about you when they tag you or use
												blockchain technology.
											</p>
										</li>

										<li>
											<b>Equipment Information.</b>
											<p>
												Information about your computer and internet connection,
												including your computer operating system, public wallet
												address, IP address, browser type, and browser language.
											</p>
										</li>

										<li>
											<b>Location Information.</b>
											<p>
												Information about the location of your device, including
												GPS location, for purposes of enhancing or facilitating
												the Services. We may also use information about the
												location of the device you are using to help us
												understand how the Services and functionality are being
												used and to deliver more relevant advertising. The
												gathering of this information may be used for
												maintaining the quality of the Services we provide, as
												well as providing overall general statistics related to
												the use of the Services. The technologies we use for
												this automatic data collection may include:
											</p>
										</li>

										<li>
											<b>Cookies.</b>
											<p>
												A cookie is a small data file stored on the hard drive
												of your computer either for only the duration of your
												visit on a website (“session cookies”) or for a fixed
												period (“persistent cookies”). Cookies contain
												information that can later be read by a web server. We
												may use cookies to provide you with a more personal and
												interactive experience on the Services.
											</p>
										</li>

										<li>
											<b>JavaScripts.</b>
											<p>
												JavaScripts are code snippets embedded in websites and
												applications that facilitate a variety of operations
												including accelerating the refresh speed of certain
												functionality or monitoring usage of various online
												components.
											</p>
										</li>

										<li>
											<b>Entity Tags.</b>
											<p>
												Entity Tags are HTTP code mechanisms that allow portions
												of websites to be stored or “cached” within your browser
												and validates these caches when the website is opened,
												accelerating website performance since the web server
												does not need to send a full response if the content has
												not changed.
											</p>
										</li>

										<li>
											<b>HTML5 Local Storage.</b>
											<p>
												HTML5 local storage allows data from websites to be
												stored or “cached” within your browser to store and
												retrieve data in HTML5 Pages when the website is
												revisited.
											</p>
										</li>

										<li>
											<b>
												Cookies, Pixel Tags/Web Beacons, and Other Technologies.
											</b>
											<p>
												We, as well as third parties that provide content,
												advertising, or other functionality on our Services, may
												use cookies, pixel tags, local storage, and other
												technologies (“Technologies”) to automatically collect
												information through your use of our Services.
											</p>
										</li>

										<li>
											<b>
												Cookies, Pixel Tags/Web Beacons, and Other Technologies.
											</b>
											<p>
												We, as well as third parties that provide content,
												advertising, or other functionality on our Services, may
												use cookies, pixel tags, local storage, and other
												technologies (“Technologies”) to automatically collect
												information through your use of our Services.
											</p>
											<ul>
												<li>
													<b>Cookies</b>
													<p>
														Cookies are small text files placed in device
														browsers that store preferences and can facilitate
														your experience when using or interacting with the
														Site or the Services.
													</p>
												</li>

												<li>
													<b>Pixel Tags/Web Beacons</b>
													<p>
														A pixel tag (also known as a web beacon) is a piece
														of code embedded in our Services that collects
														information about engagement on our Services. The
														use of a pixel tag allows us to record, for example,
														that a user has visited a particular web page or
														clicked on a particular advertisement. We may also
														include web beacons in e-mails to understand whether
														messages have been opened, acted on, or forwarded.
													</p>
												</li>

												<li>
													<b>Cookies</b>
													<p>
														Cookies are small text files placed in device
														browsers that store preferences and can facilitate
														your experience when using or interacting with the
														Site or the Services.
													</p>
												</li>
											</ul>
											<br />
											<p>
												Our uses of these Technologies fall into the following
												general categories:
											</p>
											<ul>
												<li>
													<b>Operationally Necessary.</b>
													<p>
														This includes Technologies that allow you access to
														our Services, applications, and tools that are
														required to identify irregular website behavior,
														prevent fraudulent or illegal activity and improve
														security or that allow you to make use of our
														functionality;
													</p>
												</li>

												<li>
													<b>Performance-Related.</b>
													<p>
														We may use Technologies to assess the performance of
														our Services, including as part of our analytic
														practices to help us understand how individuals use
														our Services;
													</p>
												</li>

												<li>
													<b>Functionality-Related.</b>
													<p>
														We may use Technologies that allow us to offer you
														enhanced functionality when accessing or using our
														Services. This may include identifying you when you
														sign into our Services or keeping track of your
														specified preferences, interests, or past items
														viewed;
													</p>
												</li>

												<li>
													<b>Advertising- or Targeting-Related.</b>
													<p>
														We may use first party or third-party Technologies
														to deliver content, including ads relevant to your
														interests, on our Services or on third-party
														websites.
													</p>
												</li>

												<li>
													<b>Analytics</b>
													<p>
														We may use Technologies and other third-party tools
														to process analytics information on our Services
													</p>
												</li>

												<li>
													<b>Biometric Information.</b>
													<p>
														If you consent to the collection of biometric
														information through our Partners or if the
														collection of biometric information is otherwise
														permitted, you agree that we may or our Partners may
														use facial recognition technology to extract
														biometric information contained in facial images
														that you provide. For example, we or our Partners
														may compare the face on your government-issued photo
														ID against a selfie that you provide. This biometric
														information is used for identity verification and
														fraud prevention. This information may collected by
														or be shared from or with our Partners to facilitate
														these purposes.
													</p>
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>
									<b>Information Collected from Other Sources </b>
									<br />
									<p>
										We may obtain information about you from other sources,
										including from Third Party Collaborators, our Partners,
										public databases, partner companies, and third-party
										services and organizations. For example, if you access our
										Services through a third-party application, such as an app
										store, a third-party login service, or a social networking
										site, we may collect information about you from that
										third-party application that you have made available via
										your privacy settings.
									</p>
								</li>
								<li>
									<b>How We Use Your Information.</b>
									<p>
										We or our Partners use your information for a variety of
										business purposes, including to provide our Services, for
										administrative purposes, and to market our products and
										Services, as described below.
									</p>
									<ul>
										<li>
											<b>Provide Our Services</b>
											<p>
												We use your information to fulfil our contract with you
												and provide you with our Services, such as:
											</p>

											<ul>
												<li>Managing your information and accounts; </li>
												<li>
													Providing access to certain areas, functionalities,
													and features of our Services;
												</li>
												<li>
													Authenticating the unique biometric information
													extracted from your selfie against the biometrics
													information represented on your identity document for
													identity verification and fraud prevention;
												</li>
												<li>
													Facilitating your transactions carried out via the
													Services;
												</li>
												<li>
													Activities related to Know-Your-Customer and
													Anti-Money Laundering legal requirements;
												</li>
												<li>
													Answering requests for customer or technical support;
												</li>
												<li>
													Communicating with you about your account, information
													you have provided to our Partners, activities on our
													Services, and policy changes;
												</li>
												<li>
													Processing your financial information and other
													payment methods for products or Services purchased;
												</li>
												<li>
													Processing applications if you apply for a job we post
													on our Services; and Allowing you to register for
													events.
												</li>
											</ul>
										</li>
										<li>
											<b>Administrative Purposes.</b>
											<p>
												We use your information for various administrative
												purposes, such as:
											</p>
											<ul>
												<li>
													Pursuing our legitimate interests such as direct
													marketing, research and development (including
													marketing research), network and information security,
													Know-Your Customer and Anti-Money Laundering
													requirements and purposes, and fraud prevention;
												</li>
												<li>
													Detecting security incidents, protecting against
													malicious, deceptive, fraudulent or illegal activity,
													and prosecuting those responsible for that activity;
												</li>
												<li>
													Measuring interest and engagement in our Services;
												</li>
												<li>Improving, upgrading or enhancing our Services;</li>
												<li>Developing new products and Services;</li>
												<li>Ensuring internal quality control and safety;</li>
												<li>
													Authenticating and verifying individual identities,
													including requests to exercise your rights under this
													policy;
												</li>
												<li>
													Debugging to identify and repair errors with our
													Services;
												</li>
												<li>
													Auditing relating to interactions, transactions and
													other compliance activities;
												</li>
												<li>Enforcing our agreements and policies; and</li>
												<li>Complying with our legal obligations.</li>
											</ul>
											<b>
												Marketing and Advertising our Products and Services.
											</b>
											<p>
												We may use personal information to tailor and provide
												you with content and advertisements. We may provide you
												with these materials as permitted by applicable law.
												Some of the ways we may market to you include email
												campaigns, text messages, custom audiences advertising,
												and “interest-based” or “personalized advertising,”
												including through cross-device tracking. If you have any
												questions about our marketing practices, you may contact
												us at any time as set forth in “Contact Us” below.
											</p>
										</li>
										<li>
											<b>Other Purposes</b>
											<p>
												We also use your information for other purposes as
												requested by you or as permitted by applicable law.
											</p>
										</li>
										<ul>
											<li>
												<b>Consent.</b>
												<p>
													We may use personal information for other purposes
													that are clearly disclosed to you at the time you
													provide personal information or with your consent
												</p>
											</li>
											<li>
												<b>Automated Decision Making.</b>
												<p>
													We may engage in automated decision making. We may
													rely on automated tools to help determine whether a
													kycDAO NFT or kycDAO User presents a fraud or legal
													risk. The collection, access, and/or processing of
													your personal information through kycDAO or our
													Partners, will not result in a decision based solely
													on automated processing that significantly affects you
													unless such a decision is necessary as part of a
													contract or our Partners we have with you, we have
													your consent, or we are permitted by law to engage in
													such automated decision making. If you have questions
													about our automated decision making, you may contact
													us as set forth in “Contact Us” below.
												</p>
											</li>
											<li>
												<b>De-identified and Aggregated Information.</b>
												<p>
													We may use personal information and other information
													about you to create de-identified and/or aggregated
													information, such as de-identified demographic
													information, de-identified location information,
													information about the device from which you access our
													Services, or other analyses we create. De-identified
													and/or aggregated information is not personal
													information, and we may use, disclose, and retain such
													information as permitted or required by applicable
													laws.
												</p>
											</li>
											<li>
												<b>Share Content with Friends or Colleagues. </b>
											</li>
										</ul>
									</ul>
								</li>
							</ol>
						</li>

						<li>
							<b>How We Share Your Information.</b>
							<p>
								We facilitate the disclosure your information to third parties
								for a variety of business purposes, including to provide our
								Services or to protect us or others, as described below. /or
								email notice. Your continued use of the Services after we make
								changes is deemed to be acceptance of those changes, so please
								check the Policy periodically for updates.
							</p>

							<ol>
								<li>
									<b>Disclosures to Provide our Services.</b>
									<p>
										The categories of third parties with whom we may share your
										information are described below.
									</p>
									<ul>
										<li>
											<b>Partners and Service Providers.</b>
											<p>
												We may share your personal information with our Partners
												and other third-party service providers who use that
												information to help us provide our Services. This
												includes Partners and service providers that provide us
												with IT support, biometric processing, professional
												advisors, hosting, know-your-customer/anti-money
												laundering verification services, payment processing,
												customer service, and related services. Any information
												disclosed to or otherwise collected by a Partner is
												subject to the Partner’s privacy policy. We recommend
												and encourage kycDAO Users to read and understand the
												privacy policies of each Partner before providing your
												personal information.
											</p>
										</li>
										<li>
											<b>Third-Party Services. </b>
											<p>
												Certain features and functionalities of the Services may
												link to or allow you to interface, interact, or share
												information with, access and/or use Third-Party
												Collaborators, third-party websites, services, products,
												and technology (collectively, “Third-Party Services”)
												through the Services. For example, you may use the
												Services to share your kycDAO NFT and/or the information
												summarized therein or otherwise interact with
												Third-Party Services such as Third-Party Collaborators
												and/or cryptocurrency wallets for transactional
												purposes. Any information disclosed to or otherwise
												collected by a Third-Party Service may be subject to the
												Third-Party Service’s privacy policy. You should read
												any privacy policy imposed by any Third-Party Service
												before using the Third-Party Service. We do not provide
												or control any aspect of the Third-Party Services and
												are not responsible for any privacy issues,
												compatibility issues, errors or bugs in the Services or
												Third-Party Services caused in whole or in part by the
												Third-Party Services or any update or upgrade thereto.
												We do not endorse, screen or approve, and are not
												responsible for such Third-Party Services. Providing
												information to or interacting with Third-Party Services
												is at your own risk.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<b>Disclosures to Protect Us or Other</b>
									<p>
										We may access, preserve, and disclose any information we
										store associated with you to external parties if we, in good
										faith, believe doing so is required or appropriate to:
										comply with law enforcement or national security requests
										and legal process (including those associated with Know Your
										Customer and/or Anti-Money Laundering laws and requirements,
										such as a court order or subpoena; protect your, our, or
										others’ rights, property, or safety; enforce our policies or
										contracts; collect amounts owed to us; or assist with an
										investigation or prosecution of suspected or actual illegal
										activity.
									</p>
								</li>
							</ol>
						</li>

						<li>
							<b>Your Choices.</b>
							<ol>
								<li>
									<b>Mechanisms to Control Your Information.</b>
									<p>
										We strive to provide you with choices regarding the personal
										information you provide to us. We have created mechanisms to
										provide you with the following control over your
										information:
									</p>
									<ul>
										<li>
											<b>Cookies and Other Tracking Technologies.</b>
											<p>
												You may be able to set your browser to reject cookies
												and certain other technologies by adjusting the
												appropriate settings in your browser. Each browser is
												different, but many common browsers have preferences
												that may be adjusted to allow you to either accept or
												reject cookies and certain other technologies before
												they are set or installed, or allow you to remove or
												reject the use or installation of certain technologies
												altogether. We recommend that you refer to the Help menu
												in your browser to learn how to modify your browser
												settings. Please note that you cannot remove Flash
												cookies simply by changing your browser settings. To
												learn how you can manage your Flash cookie settings,
												visit the Flash player settings page on Adobe’s website.
												If you disable or refuse cookies or other tracking
												technologies, please note that some parts of the
												Services may become inaccessible or may not function
												properly.
											</p>
										</li>
										<li>
											<b>Promotional Communications from the Company.</b>
											<p>
												If you do not wish to have your contact information used
												by the Company to promote our own or third-party
												products or services, you can opt out by (i) informing
												us of your preference at the time you establish your
												user preferences; (ii) modifying your user preferences
												in your account profile; (iii) following the opt-out
												instructions in the promotional emails we send you; or
												(iv) sending us an email stating your request. Please
												note that if you opt out of receiving promotional
												communications from us, we may still send you
												transactional communications, including emails about
												your account or purchases.
											</p>
										</li>
									</ul>
								</li>

								<li>
									<b>How We Respond to Do Not Track Signals</b>
									<p>
										“Do Not Track” is a privacy preference you can set in most
										web browsers. When you turn on this preference, it sends a
										signal or message to the websites you visit indicating that
										you do not wish to be tracked. Currently, no universally
										accepted standard exists for how to interpret such signals,
										although work to create consensus is ongoing. Accordingly,
										we do not respond to Do Not Track browser settings, but you
										can manage your choices regarding targeting advertising as
										described in Section 5.1 above.
									</p>
								</li>
							</ol>
						</li>

						<li>
							<b>Accessing and Correcting Your Information</b>
							<p>
								You may send us an email to access, correct or update any
								personal information that you have provided to us or to a
								Partner. We may also implement or develop tools available
								through the Services that allow you to manage some of this
								process or otherwise make such a request of us. We or our
								Partner may not accommodate a request if we (or our Partner)
								believe we have a legitimate business purpose to do so or it
								would violate any law or legal requirement or cause the
								information to be incorrect.
							</p>
						</li>

						<li>
							<b>Your Privacy Rights.</b>
							<p>
								In accordance with applicable law, you may have the right to:
							</p>

							<ul>
								<li>
									<b>Access and Portability of Personal Information </b>
									<p>
										about you, including: (i) confirming whether we are
										processing your personal information; (ii) obtaining access
										to or a copy of your personal information; and (iii)
										receiving an electronic copy of personal information that
										you have provided to us, or asking us to send that
										information to another company (the “right of data
										portability”);
									</p>
								</li>
								<li>
									<b>Request Correction</b>
									<p>
										of your personal information where it is inaccurate or
										incomplete. If we shared your personal data with others, we
										will tell them about the correction where possible. If you
										ask us, and where possible and lawful to do so, we will also
										tell you with whom we shared your personal data so you can
										contact them directly.
									</p>
								</li>
								<li>
									<b>Request Deletion</b>
									<p>
										of your personal information. You may ask us to delete or
										remove your personal information, such as where you withdraw
										your consent. If we shared your data with others, we will
										tell them about your request for erasure where possible. If
										you ask us, and where possible and lawful to do so, we will
										also tell you with whom we shared your personal data with so
										you can contact them directly.
									</p>
								</li>
								<li>
									<b>Request Restriction of</b>
									<p>
										our processing of your personal information in certain
										circumstances, such as where you contest the accuracy of the
										data or object to us processing it (please read below for
										information on your right to object). We will tell you
										before we lift any restriction on processing. If we shared
										your personal information with others, we will tell them
										about the restriction where possible. If you ask us, and
										where possible and lawful to do so, we will also tell you
										with whom we shared your personal information so you can
										contact them directly.
									</p>
								</li>
								<li>
									<b>Object to</b>
									<p>
										our processing of your personal information. You may ask us
										at any time to stop processing your personal information,
										and we will do so: If we are relying on a legitimate
										interest to process your personal information — unless we
										demonstrate compelling legitimate grounds for the processing
										or we need to process your data in order to establish,
										exercise, or defend legal claims; if we are processing your
										Personal Data for direct marketing. We may keep minimum
										information about you in a suppression list in order to
										ensure your choices are respected in the future and to
										comply with data protection laws (such processing is
										necessary for our and your legitimate interest in pursuing
										the purposes described above);
									</p>
								</li>
								<li>
									<b>Withdraw your Consent</b>
									<p>
										for us to process your personal information. Withdrawal of
										consent will not affect any processing of your data before
										we received notice that you wished to withdraw consent.
									</p>
								</li>
							</ul>
							<p>
								If you would like to exercise any of these rights, please
								contact us as set forth in “Contact Us” below. We will process
								such requests in accordance with applicable laws and
								regulations, in accordance with legitimate business needs and in
								compliance with applicable legal and regulatory requirements.
								Such laws and regulations require us and our Partner to retain
								personal information about you, including information summarized
								in your kycDAO NFT, after our business relationship ends.
							</p>
							<br />
							<p>
								If, in response to your request, any of your verifying personal
								or investment information is deleted or modified, we may be
								required to update the validity status of your kycDAO NFT (which
								you may no longer be able to use with Third-Party Collaborators)
								and may inform our Partners and the Third-Party Collaborators
								that may be affected.
							</p>
							<br />
							<p>
								If you have a concern about our privacy practices, including the
								way we handled your personal data or about this Privacy Policy,
								you can contract us and/or report it to the data protection
								authority for you that is authorized to hear those concerns.
							</p>
						</li>

						<li>
							<b>How We Protect Your Information.</b>
							<p>
								We take reasonable precautions to secure your personal
								information. We have implemented technical, physical, and
								administrative security measures designed to reduce the risk of
								loss, misuse, unauthorized access, disclosure, or modification
								of your information. We have put in place appropriate physical,
								electronic, and managerial procedures to safeguard the
								information we collect. All information you provide to us is
								stored on secure servers behind firewalls.
							</p>
							<br />
							<p>
								The safety and security of your information also depend on you.
								Where we have given you (or where you have chosen) a password
								for access to certain parts of the Services, you are responsible
								for keeping this password confidential. We ask you not to share
								your password with anyone.
							</p>
							<br />
							<p>
								While we have employed security technologies and procedures to
								assist safeguarding your personal information, no system or
								network can be guaranteed to be 100% secure. Unauthorized entry
								or use, hardware or software failure, and other factors may
								compromise the security of user information at any time. Any
								transmission of personal information is at your own risk. We are
								not responsible for circumvention of any privacy settings or
								security measures contained on the Site or through our Services,
								by our Partners, the Third-Party Collaborators, or any
								Third-Party Service.
							</p>
							<br />
							<p>
								All information accessed, collected, or processed by us or on
								our behalf may be transferred, processed, and stored anywhere in
								the world, including, but not limited to, the United States or
								other countries, which may have data protection laws that are
								different from the laws where you live. We endeavor to safeguard
								your information consistent with the requirements of applicable
								laws.
							</p>
						</li>

						<li>
							<b>Retention of Personal Information</b>
							<p>
								We store the personal information described in this Privacy
								Policy for as long as you use our Services or as necessary to
								fulfill the purpose(s) for which it was collected, provide our
								Services, resolve disputes, establish legal defenses, conduct
								audits, pursue legitimate business purposes, enforce our
								agreements, and comply with applicable laws and regulations.
							</p>
							<br />
							<p>
								We keep your personal information for as long as reasonably
								necessary for the purposes described in this Privacy Policy,
								while we have a legitimate business need to do so, or as
								required by law or regulation, whichever is the longer.
							</p>
							<br />
							<p>
								If you have elected to receive marketing communications from us,
								we retain information about your marketing preferences until you
								opt out of receiving these communications and in accordance with
								our policies.
							</p>
							<br />
							<p>
								To determine the appropriate retention period for your personal
								information, we will consider the amount, nature, and
								sensitivity of the personal information, the potential risk of
								harm from unauthorized use or disclosure of your personal
								information, the purposes for which we use your personal
								information and whether we can achieve those purposes through
								other means, and the applicable legal requirements.
							</p>
							<br />
							<p>
								Our Partners, and potentially our Third-Party Collaborators and
								Third-Party Services, may also store the personal information
								described in their respective privacy policies for as long as
								you use their services or as necessary to fulfill the purpose(s)
								for which it was collected, to provide their services, resolve
								disputes, establish legal defenses, conduct audits, pursue
								legitimate business purposes, enforce their agreements, for any
								other reason establishes in their applicable privacy policies,
								and to comply with applicable laws and regulations.
							</p>
						</li>

						<li>
							<b>Other Provisions</b>
							<ul>
								<li>
									<b>Supervisory Authority.</b>
									<p>
										If you are located in the European Economic Area,
										Switzerland, or the United Kingdom, you have the right to
										lodge a complaint with a supervisory authority if you
										believe our processing of your personal information violates
										applicable law.
									</p>
								</li>
								<li>
									<b>Terms.</b>
									<p>
										f you choose to visit the Services, your visit and any
										dispute over privacy is subject to this Policy and our Terms
										of Use including limitations on damages, resolution of
										disputes, and application of the law of the state of
										Delaware.
									</p>
								</li>
							</ul>
						</li>

						<li>
							<b>Contact Us</b>
							We welcome your questions, comments, and concerns about privacy.
							You can contact us at{" "}
							<LinkButton type="link-button" href={adminMail} />.
						</li>
					</ol>
				</div>
			</Container>
		</div>
	)
}
