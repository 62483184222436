import { ButtonProps } from "@Components/button/button.component"
import { clsx } from "clsx"
import { FC, useMemo } from "react"
import styled, { css } from "styled-components"

export type DropdownButtonProps = {
	isOpen: boolean
} & ButtonProps

const StyledButton = styled.button<DropdownButtonProps>`
	${({ isOpen }) =>
		isOpen &&
		css`
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		`}
`

const StyledContainer = styled.span`
	width: 100%;
	display: flex;
	justify-content: start;
	padding-left: 16px;
	border-top: 0;
`

const UnStyledDropdownButton: FC<DropdownButtonProps> = (props) => {
	const {
		buttonClass = "",
		buttonColor = "blue",
		isOpen,
		children,
		className,
		...rest
	} = props

	const classes = useMemo(
		() => clsx("kyc-button", buttonColor, buttonClass, className),
		[buttonClass, buttonColor, className]
	)

	return (
		<StyledButton {...rest} className={classes} isOpen={isOpen}>
			<i className="material-icons nav"> navigate_next </i>
			<StyledContainer>{children}</StyledContainer>
			<i className="material-icons arrow">
				{isOpen ? "expand_more" : "expand_less"}
			</i>
		</StyledButton>
	)
}

export const DropdownButton = UnStyledDropdownButton
