import CookieConsentService from "@Services/cookie-consent"
import { FC, useCallback, useEffect, useState } from "react"
import { Button } from "../button/button.component"
import styled from "styled-components"

type CookieConsent = {
	modalTitle?: string
}

const Modal = styled.div<{ show: boolean }>`
	display: ${({ show }) => (show ? "block" : "none")};
`

export const CookieConsent: FC<CookieConsent> = () => {
	const [show, setShow] = useState(false)

	const onClick = useCallback(
		(outcome: "allowed" | "rejected") => () => {
			CookieConsentService.setConsentStatus(outcome === "allowed")
			setShow(false)
		},
		[]
	)

	useEffect(() => {
		setShow(!CookieConsentService.getConsentStatus())
	}, [])

	return (
		<Modal show={show} className={"cookie-policy modal default"}>
			<div className="overlay">
				<div className="content-wrapper">
					<div className="modal-head">Cookie Policy</div>
					<p>
						We use cookies to ensure that we give you the best experience on our
						website
					</p>
					<div className="button-wrapper">
						<Button onClick={onClick("allowed")} buttonColor="primary">
							Accept
						</Button>
						<div
							onClick={onClick("rejected")}
							className="close-button kyc-button primary">
							<span>Close</span>
							<i className="material-icons">close</i>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
