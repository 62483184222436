import { clsx } from "clsx"
import { useMemo, FC, ComponentPropsWithoutRef } from "react"
import styled from "styled-components"

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
	buttonColor?: string
	buttonClass?: string // todo: remove this, makes no sense
	showArrow?: boolean
}

const ChildrenContainer = styled.span`
	display: flex;
`

export const Button: FC<ButtonProps> = (props) => {
	const {
		buttonClass = "",
		buttonColor = "blue",
		children,
		showArrow = true,
		className = "",
		...rest
	} = props

	const classes = useMemo(
		() => clsx("kyc-button", buttonColor, buttonClass, className),
		[buttonClass, buttonColor, className]
	)

	return (
		<button {...rest} className={classes}>
			<ChildrenContainer>{children}</ChildrenContainer>
			{showArrow ? (
				<i className="material-icons arrow"> arrow_forward </i>
			) : null}
		</button>
	)
}
