import { Container } from "@Components/container/container.component"
import { Hero } from "@Components/hero/hero.component"
import { LinkButton } from "@Components/link-button/link-button"
import { Link } from "react-router-dom"
import styled from "styled-components"

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`

export const PageFrontEndDeveloper = () => {
	return (
		<div className="homePage" id="careers-page">
			<Hero
				heroH1="Angular developer"
				heroH2="Fulltime - Expert"
				heroClass="small"
				containerWidth="narrow"
			/>

			<Container
				containerID="job-description"
				containerSpacing="pt-1"
				containerWidth="narrow">
				<Link to="/careers-page">
					<button className="back-button">
						<i className="material-icons">arrow_backward</i>
						<span>Back to careers</span>
					</button>
				</Link>

				<div className="job-description">
					<div className="group">
						<h2>Job description</h2>
						<p>
							At kycDAO we value the well-being of our employees and you will
							enjoy flexibility in time and work management. We are a
							remote-first company. Always looking to innovate, the scope of
							work takes in account internal and personal development and the
							opportunity to contribute to other departments of the company.
							<br />
							We are looking for a Senior Frontend Developer with impressive
							Angular experience to join our team and help us create an awesome
							experience in the web3 space.
							<br />
							<br />
							If interested, apply to the job here --&gt;
							<br />
							<br />
						</p>
					</div>
				</div>
				<div className="job-highlights">
					<div className="header">
						<p>Salary range</p>
						<h2>1M - 1.6M HUF</h2>
						<p className="comment">
							This salary has been set for full-time employment
						</p>
					</div>
					<div className="footer">
						<LinkButton
							type="dark"
							href="https://nmm1hnu8mod.typeform.com/to/IBAxdSpA"
							className="full-width">
							Apply
						</LinkButton>
					</div>
				</div>
			</Container>

			<Container containerSpacing="pt-1" containerWidth="narrow">
				<div className="text-wrapper">
					<div className="group">
						<h3>Must have</h3>

						<ul className="skill-tags">
							<li>
								<h4>Angular</h4>
							</li>
							<li>
								<h4>Javascript</h4>
							</li>
							<li>
								<h4>SCSS</h4>
							</li>
							<li>
								<h4>HTML</h4>
							</li>
							<li>
								<h4>TypeScript</h4>
							</li>
							<li>
								<h4>Git</h4>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container containerSpacing="pt-1" containerWidth="narrow">
				<div className="text-wrapper">
					<div className="group">
						<h3>Nice to have</h3>

						<ul className="skill-tags">
							<li>
								<p>Sass</p>
							</li>
							<li>
								<p>Experience with smart contracts</p>
							</li>
							<li>
								<p>end-to-end testing</p>
							</li>
							<li>
								<p>HTML</p>
							</li>
							<li>
								<p>TypeScript</p>
							</li>
							<li>
								<p>Git</p>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container containerSpacing="pt-1" containerWidth="narrow">
				<div className="text-wrapper">
					<div className="group">
						<h3>Job details</h3>

						<ul className="skill-tags perks">
							<li>
								<p>Remote First Company</p>
							</li>
							<li>
								<p>Flexible hours</p>
							</li>
							<li>
								<p>Mostly new features</p>
							</li>
							<li>
								<p>Flat structure</p>
							</li>
							<li>
								<p>Small team</p>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container containerSpacing="pt-1 pb-1" containerWidth="narrow">
				<div className="text-wrapper">
					<div className="group">
						<h3>Methodology</h3>

						<ul className="skill-tags perks">
							<li>
								<p>Agile</p>
							</li>
							<li>
								<p>GitLab code review</p>
							</li>
							<li>
								<p>Build server</p>
							</li>
						</ul>
					</div>
				</div>
			</Container>

			<Container
				containerID="application-process"
				containerSpacing="pt-2 pb-2"
				containerWidth="wide"
				containerBackground="pearl">
				<div className="wrapper">
					<h2>The application process: </h2>
					<ul className="the-process">
						<li>
							<span>1</span>
							<p>
								Apply - Introduce your self, and fill out our 5 minute form with
								your GitHub account
							</p>
						</li>
						<li>
							<span>2</span>
							<p>
								30 min long - online interview to understand your fit, and
								previous experience.
							</p>
						</li>
						<li>
							<span>3</span>
							<p>
								Complete a paid workday with Us, to have a real world experience
								about how the chemistry sparks
							</p>
						</li>
					</ul>
					<ButtonWrapper>
						<LinkButton
							type="blue"
							href="https://nmm1hnu8mod.typeform.com/to/IBAxdSpA">
							Apply Now
						</LinkButton>
					</ButtonWrapper>
				</div>
			</Container>

			<Container containerSpacing="pt-1" containerWidth="narrow" />

			<Container containerSpacing="pt-1" containerWidth="narrow" />
		</div>
	)
}
