import { FC } from "react"
import { Link } from "react-router-dom"
import { Container } from "../container/container.component"

export const OpenPositions: FC = () => {
	return (
		<Container
			containerID="open-positions"
			containerSpacing="pt-1"
			containerWidth="wide"
			containerBackground="dark">
			<div className="title">
				<i className="material-icons">person_search</i>
				<h2>Opened Positions</h2>
				<h4>See what is open right now</h4>
			</div>

			<ul className="careers-listing grid">
				<li>
					<div className="header">
						<div className="tag">frontend</div>
						<div className="job-title">
							<h4>Angular developer</h4>
							<h4>
								<span>full-time</span>
							</h4>
						</div>
						<p className="smaller-text">
							We are looking for a Senior Frontend Developer with impressive
							Angular experience to join our team and help us create an awesome
							experience in the web3 space.
						</p>
					</div>
					<Link to="/careers/front-end-developer">
						<button className="kyc-button white apply-button">
							<i className="material-icons">navigate_next</i>
							<span>Jump to career page</span>
							<i className="material-icons arrow">arrow_forward</i>
						</button>
					</Link>
				</li>
			</ul>

			<div className="footer">
				<h4>
					People mailbox<span>hr@kycdao.xyz</span>
				</h4>
			</div>
		</Container>
	)
}
