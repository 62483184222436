import { Container } from "@Components/container/container.component"
import { Link } from "react-router-dom"

export function UseCasePart() {
	return (
		<Container
			containerID="usecase-swiper"
			containerSpacing="pb-1"
			containerWidth="wide"
			containerBackground="warm">
			<div className="wrapper">
				<div className="header">
					<div>
						<h1 className="headline">
							<span>¸</span>
							Web3 use cases
						</h1>
					</div>
				</div>
				<h4>kycNFTs are the reusable & trusted building blocks of web3</h4>
			</div>

			<div className="listing">
				<ul>
					<li id="defi">
						<div className="header">
							<div className="image-wrapper">
								<img src={require("@Assets/daos.svg")} />
							</div>
							<div className="title-wrapper">
								<span>1</span>
								<h4>DeFi</h4>
							</div>
						</div>

						<p className="body">Rely on a shared compliance framework.</p>

						<div className="footer">
							<p className="examples">DEX/Lending/Yield...</p>
						</div>
					</li>

					<li id="grants">
						<div className="header">
							<div className="image-wrapper">
								<img src={require("@Assets/grants.svg")} />
							</div>
							<div className="title-wrapper">
								<span>2</span>
								<h4>Grants</h4>
							</div>
						</div>

						<p className="body">Offer anonymous compliance for grantees.</p>

						<div className="footer">
							<p className="examples">Bounty/Protocol/Ecosys...</p>
						</div>
					</li>

					<li id="communities">
						<div className="header">
							<div className="image-wrapper">
								<img src={require("@Assets/daos.svg")} />
							</div>
							<div className="title-wrapper">
								<span>3</span>
								<h5>Communities</h5>
							</div>
						</div>

						<p className="body">Trusted members to avoid regulatory scrutiny</p>

						<div className="footer">
							<p className="examples">DAO/NFT/Game/Social...</p>
						</div>
					</li>

					<li id="tokens">
						<div className="header">
							<div className="image-wrapper">
								<img src={require("@Assets/tokens.svg")} />
							</div>
							<div className="title-wrapper">
								<span>4</span>
								<h4>Assets</h4>
							</div>
						</div>

						<p className="body">Develop with compliance in mind.</p>

						<div className="footer">
							<p className="examples">RWA/TradFi/ICO...</p>
						</div>
					</li>
				</ul>

				<Link className="kyc-button secondary" to="/use-cases">
					<i className="material-icons nav"> navigate_next </i>
					<span>See all usecases</span>
					<i className="material-icons arrow"> arrow_forward </i>
				</Link>
			</div>
		</Container>
	)
}
