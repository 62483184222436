import { clsx } from "clsx"
import { FC, PropsWithChildren } from "react"

type ContainerProps = {
	containerID?: string
	containerWidth?: string
	containerBackground?: string
	containerSpacing?: string
}

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
	containerID = "",
	containerWidth = "wide",
	containerBackground = "",
	containerSpacing = "pt-1",
	children,
}) => {
	return (
		<section
			className={clsx("container-wrapper", containerWidth, containerBackground)}
			id={containerID}>
			<div className="grid-wrapper">
				<div className="grid">
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
					<span />
				</div>
			</div>
			<div
				id={containerID}
				className={`container ${containerWidth} ${containerSpacing}`}>
				{children}
			</div>
		</section>
	)
}
