import styled from "styled-components"

const ComingSoonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10em;
`

export const ComingSoonPage: React.FC<{ remainingDays: number }> = ({
	remainingDays,
}: {
	remainingDays: number
}) => (
	<ComingSoonContainer>
		<div className="coming-soon">
			<div className="logo-wrapper">
				<svg
					width="160"
					height="40"
					viewBox="0 0 160 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M49.6367 29.6343H52.2253V22.9194L55.4678 19.938L62.4978 29.6343H65.7676L57.2389 18.1921L65.4406 10.4566H62.0891L52.2253 20.0186V10.4566H49.6367V29.6343Z"
						fill="white"
					/>
					<path
						d="M83.2878 10.4566H80.3451L74.6502 19.5888L68.8191 10.4566H65.7401L73.2606 21.7913V29.6343H75.8491V21.7913L83.2878 10.4566Z"
						fill="white"
					/>
					<path
						d="M101.747 16.2314C101.174 12.1488 97.7957 10 93.7358 10C87.7412 10 84.5259 14.5124 84.5259 20.0992C84.5259 25.6859 87.496 30.0909 93.5178 30.0909C98.4497 30.0909 101.42 27.2169 101.937 22.4091H99.3489C99.1309 25.5785 97.1145 27.9421 93.7358 27.9421C89.0764 27.9421 87.1145 24.2892 87.1145 19.9112C87.1145 15.9091 89.0764 12.1488 93.7085 12.1488C96.4333 12.1488 98.5859 13.5186 99.1581 16.2314H101.747Z"
						fill="white"
					/>
					<path
						d="M107.733 12.6054H112.092C116.861 12.6054 118.577 15.3719 118.577 19.938C118.577 27.0021 113.809 27.4855 112.038 27.4855H107.733V12.6054ZM105.144 29.6343H111.82C118.305 29.6343 121.166 26.0083 121.166 19.5888C121.166 13.438 117.814 10.4566 111.82 10.4566H105.144V29.6343Z"
						fill="white"
					/>
					<path
						d="M130.978 12.7665H131.032L134.275 21.7107H127.681L130.978 12.7665ZM122.013 29.6343H124.684L126.863 23.8595H135.092L137.218 29.6343H140.079L132.477 10.4566H129.615L122.013 29.6343Z"
						fill="white"
					/>
					<path
						d="M143.497 20.0454C143.497 16.1777 145.486 12.1488 150.227 12.1488C154.968 12.1488 156.957 16.1777 156.957 20.0454C156.957 23.9132 154.968 27.9421 150.227 27.9421C145.486 27.9421 143.497 23.9132 143.497 20.0454ZM140.908 20.0454C140.908 25.283 144.042 30.0909 150.227 30.0909C156.412 30.0909 159.546 25.283 159.546 20.0454C159.546 14.8078 156.412 10 150.227 10C144.042 10 140.908 14.8078 140.908 20.0454Z"
						fill="white"
					/>
					<rect width="40" height="40" rx="3" fill="#00FFB3" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.8567 8.13896L28.9685 6.25L20.0005 15.2225L17.6405 12.8613L15.7525 14.7503L20.0005 19.0005L30.8567 8.13896ZM9.14453 8.1393L13.8646 12.8617L15.7526 10.9727L11.0326 6.25034L9.14453 8.1393Z"
						fill="black"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.8538 23.8763L28.9658 21.9873L19.9977 30.9598L17.6377 28.5986L15.7497 30.4876L19.9977 34.7377L30.8538 23.8763ZM9.14258 23.8771L13.8626 28.5996L15.7506 26.7106L11.0306 21.9881L9.14258 23.8771Z"
						fill="black"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M30.8557 15.8616L26.1355 20.584L24.2475 18.695L28.9677 13.9727L30.8557 15.8616ZM9.14453 15.862L11.0326 13.9731L20.0006 22.9456L22.3607 20.5844L24.2488 22.4733L20.0006 26.7236L9.14453 15.862Z"
						fill="black"
					/>
				</svg>
			</div>
			<div className="coming-soon-content-wrapper">
				<div className="text">
					<h1>We are going live soon</h1>
					<h1>Stay tuned!</h1>
				</div>
				<div className="illustration">
					<span className="left" />
					<span className="right" />
					<img
						src={require("@Assets/day-" + remainingDays + ".svg")}
						alt="remaining-days"
					/>
				</div>
			</div>
		</div>
	</ComingSoonContainer>
)
