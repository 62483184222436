import { FC, useMemo, useState, useCallback, useRef, useEffect } from "react"

import { Link, useLocation } from "react-router-dom"
import { Logo } from "./logo"
import { clsx } from "clsx"
import { ChainSelector } from "@Components/chain-selector/chain-selector"
import { BlockchainNetworks } from "@kycdao/kycdao-sdk"
import WalletConnectProvider from "@walletconnect/web3-provider"
import type { Wallets } from "../../config/_interface"
import { KycDaoClient } from "@kycdao/widget"
import { KycDaoClientInterface } from "@kycdao/widget/dist/KycDaoClientCommon"
import { LinkButton } from "@Components/link-button/link-button"
import { docSite, github, kycDAODiscord, twitter } from "@Services/adresses"

export const Nav: FC = () => {
	const { pathname } = useLocation()

	const [active, setActive] = useState(false)
	const kycDaoClient = useRef<KycDaoClientInterface>()
	const kycDaoClientParent = useRef<Element>()

	useEffect(() => {
		const root = document.querySelector("#modal-root")
		if (root) {
			kycDaoClientParent.current = root

			kycDaoClient.current = new KycDaoClient({
				...Config.WidgetBaseConfig,
				config: {
					...Config.WidgetCoreConfig,
					enabledVerificationTypes: Config.enabledVerificationTypes,
					evmProvider: window.ethereum,
				},
				onReady: (sdk) => console.log(sdk),
				onFail: (reason) => console.log(reason),
				onSuccess: (data) => console.log(data),
				parent: root as HTMLElement,
			})
		}
	}, [])

	const onChainSelectionClose = useCallback(
		async (chain: keyof typeof BlockchainNetworks, wallet: Wallets) => {
			if (
				chain &&
				wallet &&
				kycDaoClient.current &&
				kycDaoClientParent.current
			) {
				const evmProvider =
					wallet === "WalletConnect"
						? new WalletConnectProvider(Config.walletConnectConfig)
						: window.ethereum
				kycDaoClient.current.open(chain, evmProvider)
			}
		},
		[]
	)

	const onSetActive = useCallback(() => {
		setActive((old) => !old)
	}, [])

	const classes = useMemo(() => {
		return clsx("mobile-menu", active && "active")
	}, [active])

	return pathname.search("/confirm") ? (
		<header id="main-nav" className="">
			<div className="desktop-menu-wrapper">
				<Link id="logo" to="/">
					<Logo />
					<p className="beta">beta</p>
				</Link>
				<div className="menu">
					<ul>
						{/*<li><Link to="/careers-page">Careers</Link></li>*/}
						{pathname !== "/join" && (
							<>
								<li>
									<LinkButton safeNewTab href={docSite}>
										Docs
									</LinkButton>
								</li>
								<li>
									<Link to="/use-cases">Use cases</Link>
								</li>
								<li>
									<Link to="/faq">FAQ</Link>
								</li>
								<li className="contact primary">
									<Link to="/contact">
										<span>Contact</span>
									</Link>
								</li>
							</>
						)}
						{pathname === "/join" && (
							<ChainSelector onChainSelectionClose={onChainSelectionClose} />
						)}
					</ul>

					<div className="menu-opener-wrapper">
						<div className="menu-opener" onClick={onSetActive}>
							<i className="material-icons">menu</i>
						</div>
					</div>
				</div>
			</div>

			<div className={classes} id="mobile-menu">
				<div className="menu-closer" onClick={onSetActive}>
					<i className="material-icons">close</i>
				</div>

				<ul>
					<li>
						<LinkButton safeNewTab onClick={onSetActive} href={docSite}>
							Docs
						</LinkButton>
					</li>

					{/*<li>*/}
					{/*	<Link to="/careers-page">Careers</Link>*/}
					{/*</li>*/}

					<li>
						<Link onClick={onSetActive} to="/use-cases">
							Use cases
						</Link>
					</li>
					<li>
						<Link onClick={onSetActive} to="/faq">
							FAQ
						</Link>
					</li>
				</ul>

				<LinkButton safeNewTab onClick={onSetActive} href={docSite}>
					Docs
				</LinkButton>

				<LinkButton onClick={onSetActive} href="/join" type="primary">
					Join us
				</LinkButton>

				<ul className="secondary">
					<li>
						<LinkButton safeNewTab onClick={onSetActive} href={twitter}>
							Twitter
						</LinkButton>
					</li>

					<li>
						<LinkButton safeNewTab onClick={onSetActive} href={kycDAODiscord}>
							Discord
						</LinkButton>
					</li>

					<li>
						<LinkButton safeNewTab onClick={onSetActive} href={github}>
							Github
						</LinkButton>
					</li>
				</ul>
			</div>
		</header>
	) : (
		<></>
	)
}
