import { DropdownMenuProps } from '@Components/dropdownMenu/dropdownMenu'
import { useCallback, useMemo, useState } from 'react'
import { Wallets } from '../config/_interface'
import { BlockchainNetworks } from '@kycdao/kycdao-sdk'

type AllBlockchainNetworks = keyof typeof BlockchainNetworks

const { allChains, allWallets } = Config

const useNetworksAndChains = () => {
	const [currentWallet, setCurrentWallet] = useState<Wallets>()
	const [currentChain, setCurrentChain] = useState<AllBlockchainNetworks>()

	const chainButtonConfig: DropdownMenuProps['items'] = useMemo(() => {
		switch (currentWallet) {
			case 'MetaMask':
			case 'WalletConnect':
				return [
					allChains.Celo,
					allChains.Polygon,
					allChains.Base,
					null,
					allChains.Near,
					allChains.Solana,
					allChains.Aptos,
				].filter((e) => e !== undefined)
			case 'Near':
				return [
					allChains.Near,
					null,
					allChains.Celo,
					allChains.Polygon,
					allChains.Base,
					allChains.Solana,
					allChains.Aptos,
				].filter((e) => e !== undefined)
			case 'Phantom':
				return [
					allChains.Solana,
					null,
					allChains.Celo,
					allChains.Polygon,
					allChains.Base,
					allChains.Near,
					allChains.Aptos,
				].filter((e) => e !== undefined)
			case 'Petra':
				return [
					allChains.Aptos,
					null,
					allChains.Celo,
					allChains.Polygon,
					allChains.Base,
					allChains.Near,
					allChains.Solana,
				].filter((e) => e !== undefined)
			default: {
				return Object.values(allChains).filter((e) => e !== undefined)
			}
		}
	}, [currentWallet])

	const walletButtonConfig: DropdownMenuProps['items'] = useMemo(() => {
		switch (currentChain) {
			case 'CeloAlfajores':
			case 'CeloMainnet':
			case 'EthereumGoerli':
			case 'EthereumMainnet':
			case 'PolygonMainnet':
			case 'PolygonMumbai':
			case 'BaseGoerli':
				return [
					allWallets.MetaMask,
					allWallets.WalletConnect,
					null,
					allWallets.Near,
					allWallets.Phantom,
					allWallets.Petra,
				].filter((e) => e !== undefined)
			case 'NearMainnet':
			case 'NearTestnet':
				return [
					allWallets.Near,
					null,
					allWallets.MetaMask,
					allWallets.WalletConnect,
					allWallets.Phantom,
					allWallets.Petra,
				].filter((e) => e !== undefined)
			case 'SolanaDevnet':
			case 'SolanaMainnet':
			case 'SolanaTestnet':
				return [
					allWallets.Phantom,
					null,
					allWallets.MetaMask,
					allWallets.WalletConnect,
					allWallets.Near,
					allWallets.Petra,
				].filter((e) => e !== undefined)
			case 'AptosDevnet':
			case 'AptosMainnet':
			case 'AptosTestnet':
				return [
					allWallets.Petra,
					null,
					allWallets.MetaMask,
					allWallets.WalletConnect,
					allWallets.Near,
					allWallets.Phantom,
				].filter((e) => e !== undefined)
			default: {
				return Object.values(allWallets).filter((e) => e !== undefined)
			}
		}
	}, [currentChain])

	const onChainSelected = useCallback(
		(network: AllBlockchainNetworks) => {
			setCurrentChain(network)

			switch (network) {
				case 'CeloAlfajores':
				case 'CeloMainnet':
				case 'EthereumGoerli':
				case 'EthereumMainnet':
				case 'PolygonMainnet':
				case 'PolygonMumbai':
				case 'BaseGoerli':
					switch (currentWallet) {
						case 'Near':
						case 'Phantom':
						case 'Petra':
							setCurrentWallet(undefined)
					}
					return
				case 'NearMainnet':
				case 'NearTestnet':
					switch (currentWallet) {
						case 'MetaMask':
						case 'Phantom':
						case 'WalletConnect':
						case 'Petra':
							setCurrentWallet(undefined)
					}
					return
				case 'SolanaDevnet':
				case 'SolanaMainnet':
				case 'SolanaTestnet':
					switch (currentWallet) {
						case 'MetaMask':
						case 'Near':
						case 'WalletConnect':
						case 'Petra':
							setCurrentWallet(undefined)
					}
					return
				case 'AptosDevnet':
				case 'AptosMainnet':
				case 'AptosTestnet':
					switch (currentWallet) {
						case 'MetaMask':
						case 'Near':
						case 'WalletConnect':
						case 'Phantom':
							setCurrentWallet(undefined)
					}
					return
			}
		},
		[currentWallet]
	)

	const onWalletSelected = useCallback(
		(wallet: Wallets) => {
			setCurrentWallet(wallet)

			switch (wallet) {
				case 'MetaMask':
				case 'WalletConnect':
					switch (currentChain) {
						case 'NearMainnet':
						case 'NearTestnet':
						case 'SolanaDevnet':
						case 'SolanaMainnet':
						case 'SolanaTestnet':
						case 'AptosDevnet':
						case 'AptosMainnet':
						case 'AptosTestnet':
							setCurrentChain(undefined)

							return
					}

					return
				case 'Near':
					switch (currentChain) {
						case 'CeloAlfajores':
						case 'CeloMainnet':
						case 'EthereumGoerli':
						case 'EthereumMainnet':
						case 'PolygonMainnet':
						case 'PolygonMumbai':
						case 'BaseGoerli':
						case 'SolanaTestnet':
						case 'SolanaMainnet':
						case 'SolanaDevnet':
						case 'AptosDevnet':
						case 'AptosMainnet':
						case 'AptosTestnet':
							setCurrentChain(undefined)

							return
					}
					return
				case 'Phantom':
					switch (currentChain) {
						case 'CeloAlfajores':
						case 'CeloMainnet':
						case 'EthereumGoerli':
						case 'EthereumMainnet':
						case 'PolygonMainnet':
						case 'PolygonMumbai':
						case 'BaseGoerli':
						case 'NearMainnet':
						case 'NearTestnet':
						case 'AptosDevnet':
						case 'AptosMainnet':
						case 'AptosTestnet':
							setCurrentChain(undefined)

							return
					}
					return
				case 'Petra':
					switch (currentChain) {
						case 'CeloAlfajores':
						case 'CeloMainnet':
						case 'EthereumGoerli':
						case 'EthereumMainnet':
						case 'PolygonMainnet':
						case 'PolygonMumbai':
						case 'BaseGoerli':
						case 'NearMainnet':
						case 'NearTestnet':
						case 'SolanaTestnet':
						case 'SolanaMainnet':
						case 'SolanaDevnet':
							setCurrentChain(undefined)

							return
					}
				default: {
					return Object.values(allChains)
				}
			}
		},
		[currentChain]
	)

	return {
		chainButtonConfig,
		currentChain,
		currentWallet,
		onChainSelected,
		onWalletSelected,
		walletButtonConfig,
	}
}

export default useNetworksAndChains
