import { ConfigInterface } from './_interface'

const Chains =
  process.env.USE_TESTNETS == 'true'
    ? {
        Polygon: 'PolygonMumbai',
        Celo: 'CeloAlfajores',
        Near: 'NearTestnet',
        Solana: 'SolanaDevnet',
        Base: 'BaseGoerli',
        Aptos: 'AptosTestnet',
      }
    : {
        Polygon: 'PolygonMainnet',
        Celo: 'CeloMainnet',
        Near: 'NearMainnet',
        Solana: 'SolanaMainnet',
        Aptos: 'AptosMainnet',
      }

export const Wallets = {
  MetaMask: 'MetaMask',
  WalletConnect: 'WalletConnect',
  Near: 'Near',
  Phantom: 'Phantom',
  Petra: 'Petra',
}

const allWallets = {
  WalletConnect: {
    id: 'WalletConnect',
    label: 'Wallet Connect',
    icon: require('@Assets/logos/walletconnect-logo.svg'),
  },
  Near: {
    label: 'Near',
    id: 'Near',
    icon: require('@Assets/logos/near-icon.svg'),
    darkIcon: require('@Assets/logos/near-icon-wht.svg'),
  },
  MetaMask: {
    label: 'MetaMask',
    id: 'MetaMask',
    icon: require('@Assets/logos/metamask-logo.svg'),
  },
  Phantom: {
    id: 'Phantom',
    label: 'Phantom',
    icon: require('@Assets/phantom-icon-purple.png'),
  },
  Petra: {
    id: 'Petra',
    label: 'Petra',
    icon: require('@Assets/logos/petra-logo.svg'),
  },
}

const allChains = {
  Polygon: {
    label: 'Polygon',
    icon: require('@Assets/logos/polygon-logo.svg'),
    className: 'white',
    id: Chains.Polygon,
  },
  Celo: {
    label: 'Celo',
    icon: require('@Assets/logos/celo-onyx.svg'),
    darkIcon: require('@Assets/logos/celo-snow.svg'),
    className: 'white',
    id: Chains.Celo,
  },
  Base: {
    label: 'Base',
    icon: require('@Assets/logos/base-logo.svg'),
    darkIcon: require('@Assets/logos/base-logo-in-blue.svg'),
    className: 'white',
    id: Chains.Base,
  },
  Near: {
    label: 'Near',
    icon: require('@Assets/logos/near-logo.svg'),
    darkIcon: require('@Assets/logos/near-icon-wht.svg'),
    className: 'white',
    id: Chains.Near,
  },
  Solana: {
    label: 'Solana',
    icon: require('@Assets/logos/solana-logo.svg'),
    id: Chains.Solana,
    className: 'white',
  },
  Aptos: {
    label: 'Aptos',
    icon: require('@Assets/logos/aptos-logo.svg'),
    darkIcon: require('@Assets/logos/aptos-logo-wht.svg'),
    id: Chains.Aptos,
    className: 'white',
  },
}

export default {
  enabledVerificationTypes: ['KYC'],
  walletConnectConfig: {
    rpc: {
      1: 'https://rpc.ankr.com/eth',
      5: 'https://rpc.ankr.com/eth_goerli',
      137: 'https://rpc-mainnet.maticvigil.com/',
      42220: 'https://forno.celo.org',
      44787: 'https://alfajores-forno.celo-testnet.org',
      80001: 'https://rpc-mumbai.maticvigil.com/',
      84531: 'https://goerli.base.org',
    },
    chainId: 80001,
  },
  WidgetBaseConfig: {
    modal: true,
    configFromUrl: false,
    backdrop: true,
  },
  WidgetCoreConfig: {
    enabledBlockchainNetworks:
      process.env.USE_TESTNETS == 'true'
        ? [
            'CeloAlfajores',
            'NearTestnet',
            'PolygonMumbai',
            'BaseGoerli',
            'SolanaDevnet',
            'SolanaTestnet',
            'AptosTestnet',
          ]
        : ['CeloMainnet', 'NearMainnet', 'PolygonMainnet', 'AptosMainnet'],
    demoMode: false,
    enabledVerificationTypes: ['KYC'],
    baseUrl: process.env.BACKEND_BASE_URL,
    publicApiPath: process.env.BACKEND_API_PATH,
    sentryConfiguration: {
      dsn: 'https://23dafecec027439b9413cd50eb22567d@o1184096.ingest.sentry.io/4504559638413313',
    },
  },
  Chains,
  allChains,
  allWallets,
  Wallets,
  GrowthbookConfig: {
    apiHost: 'https://growthbook-api.kycdao.xyz',
    clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  },
  siteVersion: process.env.VERCEL_GIT_COMMIT_SHA,
} as ConfigInterface
