import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import dark from "react-syntax-highlighter/dist/esm/styles/prism/darcula"

export const codeString1 = `npm install @kydao/widget`

export const codeString2 = `<!DOCTYPE html>
   <html>
      <body>
		   <div id="modal-root"></div>
	   </body>
</html>
`

export const codeString3 = `import { KycDaoClient } from "@kycdao/widget"
import { KycDaoClientInterface } from "@kycdao/widget/dist/KycDaoClientCommon"
`

export const codeString4 = `const parent = document.querySelector("#modal-root");
let kycDaoClient

if (parent) {
  kycDaoClient = new KycDaoClient({
	modal: true,
	configFromUrl: false,
	backdrop: true,
    config: {
      enabledBlockchainNetworks: [
        "CeloAlfajores"
      ],
      demoMode: true,
      enabledVerificationTypes: ["KYC"],
      baseUrl: "https://main.pre.kycdao.xyz",
      sentryConfiguration: {
        dsn: "https://23dafecec027439b9413cd50eb22567d@o1184096.ingest.sentry.io/4504559638413313",
      },
      evmProvider: window.ethereum,
    },
    onReady: (sdk) => console.log(sdk),
    onFail: (reason) => console.log(reason),
    onSuccess: (data) => console.log(data),
    parent,
  });
}`

const codeString5 = `if (kycDaoClient) {
	kycDaoClient.open()
}`

export function CodeExample() {
	return (
		<div className="code-wrapper">
			<div className="header">
				<i className="material-icons">code</i>
				<h4>Quickstart with kycDAO client </h4>
			</div>

			<div className="code-content">
				<ul>
					<li>
						<div className="code-example">
							<div className="step-instruction">
								<span>1</span>
								<p>Add the Widget package to your project</p>
							</div>

							<SyntaxHighlighter
								language="jsx"
								style={dark}
								showLineNumbers={true}
								wrapLongLines={true}>
								{codeString1}
							</SyntaxHighlighter>
						</div>
					</li>

					<li>
						<div className="code-example">
							<div className="step-instruction">
								<span>2</span>
								<p>Add a root element to your html</p>
							</div>

							<SyntaxHighlighter
								language="html"
								style={dark}
								showLineNumbers={true}
								wrapLongLines={true}>
								{codeString2}
							</SyntaxHighlighter>
						</div>
					</li>

					<li>
						<div className="code-example large">
							<div className="step-instruction">
								<span>3</span>
								<p>Import the Widget client</p>
							</div>

							<SyntaxHighlighter
								language="jsx"
								style={dark}
								showLineNumbers={true}
								wrapLongLines={true}>
								{codeString3}
							</SyntaxHighlighter>
						</div>
					</li>

					<li>
						<div className="code-example large">
							<div className="step-instruction">
								<span>4</span>
								<p>Create an client instance then open it</p>
							</div>

							<SyntaxHighlighter
								language="jsx"
								style={dark}
								showLineNumbers={true}
								wrapLongLines={true}>
								{codeString4}
							</SyntaxHighlighter>
						</div>
					</li>

					<li>
						<div className="code-example large">
							<div className="step-instruction">
								<span>5</span>
								<p>Open the client</p>
							</div>

							<SyntaxHighlighter
								language="jsx"
								style={dark}
								showLineNumbers={true}
								wrapLongLines={true}>
								{codeString5}
							</SyntaxHighlighter>
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}
