class CookieConsentService {
	getConsentStatus(): boolean {
		const status = document.cookie
			?.split(';')
			.some((val) => val.trim() === 'consent=ok')

		return status
	}

	setConsentStatus(consented: boolean) {
		document.cookie = `consent=${consented ? 'ok' : 'no'}`
	}
}

export default new CookieConsentService()
